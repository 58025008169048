<template>
  <v-card
    class="pa-2"
    tile
    height="100%"
    outlined>
    <v-row
      class="text-center mt-1">
        <v-col cols="12"
          sm="5"
          md="5">
          <v-text-field
            label="Enter Card Name"
            v-model="selectedCardConfig.Title"
            hide-details="auto">
          </v-text-field>
        </v-col>
        <v-col>
          <v-btn
            v-if="selectedCardConfig.Title != ''"
            elevation="2"
            large
            raised
            @click="uploadDefault">
            Select Default Picture</v-btn>
        </v-col>
    </v-row>
    <v-row
      class="text-center mt-1"
      cols="12"
      sm="12"
      md="12">
        <v-col>
          <v-text-field
            label="Enter Card Width"
            disabled
            v-model="selectedCardConfig.width"
            hide-details="auto">
          </v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            label="Enter Card Height"
            disabled
            v-model="selectedCardConfig.height"
            hide-details="auto">
          </v-text-field>
        </v-col>
    </v-row>
    <v-row
      class="text-center mt-1"
      cols="10"
      sm="10"
      md="10">
        <v-col>
          <v-checkbox
            v-model="selectedCardConfig.allowLogoSelection"
            label="Allow Logo Selection"
            color="primary"
          ></v-checkbox>
        </v-col>
        <v-col>
          <v-checkbox
            v-model="selectedCardConfig.allowMaterialSelection"
            label="Allow Material Selection"
            color="primary"
          ></v-checkbox>
        </v-col>
        <v-col>
          <v-checkbox
            v-model="selectedCardConfig.allowColorSelection"
            label="Allow Color Selection"
            color="primary"
          ></v-checkbox>
        </v-col>
        <v-col>
          <v-checkbox
            v-model="selectedCardConfig.pictureOnTop"
            label="Picture on Top of Template"
            color="primary"
          ></v-checkbox>
        </v-col>
    </v-row>
    <v-row
      class="text-center mt-1"
      cols="10"
      sm="10"
      md="10">
        <v-col>
          <v-select
            v-model="selectedCardConfig.Font"
            :items="Fonts"
            label="Select font"
            outlined
          ></v-select>
        </v-col>
        <v-col>
          <v-select
            :items="Orientation"
            item-text="Text"
            item-value="Value"
            hide-details="auto"
            label="Orientation"
            v-model="selectedCardConfig.Orientation"
            @change="changeOrientation"
            outlined></v-select>
        </v-col>
    </v-row>
    <p><img id="outputGeneral" class="mt-8" width="200" :src="selectedCardConfig.Template"/></p>
  </v-card>
</template>

<script>

import setup from '@/mixins/setup';

export default {
  data: () => ({
    Orientation: [
      { Text: 'Horizontal', Value: 'H' },
      { Text: 'Vertical', Value: 'V' }],
    Fonts: ['Droid Sans', 'Droid Serif', 'Cabin', 'Libre Baskerville', 'Space Grotesk', 'Bodoni Moda', 'GFS Didot', 'Times New Roman', 'Roboto', 'sans-serif', 'Chango'],
  }),
  name: 'SetupGeneral',
  mixins: [setup],
};
</script>

import { mapActions, mapGetters } from 'vuex';
// import axios from 'axios';

const control = {
  data() {
    return {
    };
  },
  created() {
    this.ListOfTemplates();
  },
  computed: {
    ...mapGetters('canvasState', { allTemplates: 'allTemplates' }),
  },
  methods: {
    ...mapActions('canvasState', ['setSelectedCard', 'setSelectedCardConfig', 'ListOfTemplates', 'deleteCard']),
    getListTemplates() {
      this.ListOfTemplates();
    },
  },
};

export default control;

<template>
  <v-container
    class="pa-2"
    height="100%">
    <v-row
      class="text-center mt-1"
      cols="10"
      sm="10"
      md="10">
      <v-col>
        <template v-if="layersProps.Accents.length > 0" >
          <template v-for="(accent, Index) in layersProps.Accents">
            <v-row  :key="'R'+Index">
              <v-col :key="'A'+Index">
                <v-btn @click="setAccent(Index)">
                  Select Color {{ layersProps.Accents[Index].Title }}
                  </v-btn>
              </v-col>
            </v-row>
          </template>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import userModule from '@/mixins/userModule';

export default {
  name: 'CustomizeColors',
  mixins: [userModule],
};
</script>

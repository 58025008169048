const control = {
  data() {
    return {
    };
  },
  methods: {
  },
};

export default control;

<template>
  <v-container
    class="pa-2"
    height="100%">
    <v-row
      class="text-center mt-1"
      cols="10"
      sm="10"
      md="10">
        <v-col>
        <template v-if="layersProps.Logos.length > 0" >
          <v-select
            v-model="canvasProps.SelectedLogo"
            :items="layersProps.Logos"
            item-text="Title"
            item-value="Index"
            label="Select Logo"
            @change="setTeamLogo"
            outlined
          ></v-select>
        </template><div class="transition-swing text-h4 mb-8 mt-4 d-none d-sm-block text-center">
          Upload Your Own Logo</div>
        <v-btn
          elevation="2"
          large
          raised
          color='success'
          class="white--text"
          @click="uploadLogo">
          Upload Logo</v-btn>
          <v-row>
            <v-col cols="12"
                  sm="12"
                  md="12">
              <v-slider
                label="Transparency"
                v-model="layers[constants.defaultIndexTeamLogo].alpha"
                max="1"
                step="0.05"
                hide-details="auto"
                min="0"></v-slider>
            </v-col>
            <v-col cols="12"
                  sm="12"
                  md="12">
              <v-slider
                label="Scale"
                v-model="layers[constants.defaultIndexTeamLogo].scale"
                max="1"
                step="0.01"
                hide-details="auto"
                min="0"></v-slider>
            </v-col>
            <v-col cols="6"
                  sm="6"
                  md="6">
                <v-row>
                  <v-checkbox cols="9" sm="9" md="9"
                    v-model="selectedCard.showShapeBehindLogo"
                    label="Show Shape"
                    color="primary" hide-details="auto"
                    ></v-checkbox>
                </v-row>
            </v-col>
            <v-col cols="6"
                  sm="6"
                  md="6">
                <v-row>
                    <ColorPickerComp cols="3" sm="3" md="3"
                      :colorExport='selectedCard.showShapeColor'
                      :module="'logos'"
                      :index='1'/>
                </v-row>
            </v-col>
          </v-row>
        </v-col>
    </v-row>
  </v-container>
</template>

<script>

import userModule from '@/mixins/userModule';
import ColorPickerComp from '@/components/general/ColorPicker.vue';

export default {
  name: 'CustomizeLogos',
  mixins: [userModule],
  components: { ColorPickerComp },
};
</script>

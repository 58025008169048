<template>
  <v-app id="inspire">
    <v-app-bar
      app
      color="white"
      flat>
      <v-container class="py-0 fill-height">
        <v-avatar
          class="mr-10"
          color="grey darken-1"
          size="32"
        ></v-avatar>
        <NavMenu/>
        <v-spacer></v-spacer>

        <v-responsive max-width="260">
          <v-text-field
            dense
            flat
            hide-details
            rounded
            solo-inverted
          ></v-text-field>
        </v-responsive>
      </v-container>
    </v-app-bar>

    <router-view></router-view>
    <v-overlay :value="isLoading">
      <v-progress-circular
        indeterminate
        :size="90"
        :width="10"
      ></v-progress-circular>
    </v-overlay>
  </v-app>
</template>

<script>

import NavMenu from '@/components/general/Nav-Menu.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'App',

  components: {
    NavMenu,
  },
  computed: {
    ...mapGetters('canvasState', { isLoading: 'isLoading' }),
  },
  data: () => ({
  }),
  methods: {
  },
  created() {
  },
};
</script>

<template>
  <v-card
    class="pa-2"
    height="100%"
    tile
    outlined>
    <v-row
      class="text-center mt-1"
      cols="10"
      sm="10"
      md="10">
        <v-col>
          <v-btn
            elevation="2"
            large
            raised
            @click="addAccents">
            Add Color</v-btn>
          <p></p>
          <template v-if="layersConfigProps.Accents.length > 0" >
            <template v-for="(accent, Index) in layersConfigProps.Accents">
              <v-row  :key="'R'+Index">
                <v-col :key="'A'+Index">
                  <v-text-field
                    :key="Index"
                    label="Enter Accent Name"
                    v-model="layersConfigProps.Accents[Index].Title"
                    hide-details="auto">
                    </v-text-field>
                </v-col>
                <v-col :key="'BM'+Index"
                      cols="12"
                      sm="2"
                      md="2">
                      <v-btn
                        v-if="accent.Title != ''"
                        :key="Index"
                        elevation="2"
                        color="success"
                        outlined
                        @click="uploadAccent(Index)">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                            v-bind="attrs"
                            v-on="on">mdi-upload</v-icon>
                          </template>
                          <span>Upload Color</span>
                        </v-tooltip>
                      </v-btn>
                </v-col>
                <v-col
                      :key="'DM'+Index"
                      cols="12"
                      sm="2"
                      md="2">
                      <v-btn
                        v-if="accent.Title != ''"
                        :key="Index"
                        elevation="2"
                        raised
                        color="error"
                        @click="removeAccents(Index)">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                            v-bind="attrs"
                            v-on="on">mdi-delete</v-icon>
                          </template>
                          <span>Delete Color</span>
                        </v-tooltip></v-btn>
                </v-col>
                <input :id="'UploadAccent' + Index"
                class="hidden"
                style="display: none;"
                type="file"
                accept=".jpeg, .jpg, .png"
                @change="loadAccent($event, Index)">
              </v-row>
            </template>
          </template>
        </v-col>
    </v-row>
  </v-card>
</template>

<script>

import setup from '@/mixins/setup';

export default {
  name: 'SetupAccents',
  mixins: [setup],
};
</script>

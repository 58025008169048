<template>
    <v-card
        class="carousel__item">
        <v-img
        :src='imageSrc'
        :lazy-src='imageLazy'
        aspect-ratio="1"
        class="grey lighten-2 carousel__Picture"
        @click="handleClick()">
            <template v-slot:placeholder>
            <v-row
            class="fill-height ma-0"
            align="center"
            justify="center">
            <v-progress-circular
                indeterminate
                color="grey lighten-5"
            ></v-progress-circular>
            </v-row>
        </template>
        </v-img>
        <v-card-title>
          <v-row cols="10">
            <v-col cols="9">
            {{ title }}
            </v-col>
            <v-col cols="1" class="text-right" v-if="deleteCard">
              <v-btn
                dark
                small
                color="red"
                @click="handleDeleteCard()">
                <v-icon dark>
                  mdi-delete
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
    </v-card>
</template>

<script>

export default {
  name: 'CardTemplate',
  props: {
    title: {
      type: String,
      default: '',
    },
    cardKey: {
      type: String,
      default: '',
    },
    imageSrc: {
      type: String,
      default: '',
    },
    imageLazy: {
      type: String,
      default: '',
    },
    deleteCard: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleClick() {
      this.$emit('onClick');
    },
    handleDeleteCard() {
      this.$emit('onClickDelete');
    },
  },
};
</script>

import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';

Vue.use(require('vue-easeljs'));

const userPicture = {
  data() {
    return {
      isMoving: false,
      isSaving: false,
      isValid: true,
      rules: {
        email: (v) => !!(v === '' ? '@' : v || '').match(/@/) || 'Please enter a valid email',
        length: (len) => (v) => (v || '').length >= len || `Invalid character length, required ${len}`,
        maxLength: (len) => (v) => (v || '').length <= len || `Límite de caracteres (${len})`,
        required: (v) => !!v || 'This field is required',
        isNumber: (value) => {
          // eslint-disable-next-line no-new-wrappers
          const numero = new Number(value);

          return numero instanceof Number || 'Campo debe ser un número';
        },
      },
      mousePosition: {},
      picPosition: {},
      userName: '',
      userPic: '',
    };
  },
  computed: {
    ...mapGetters('canvasState', { layers: 'Layers' }),
    ...mapGetters('canvasState', { layersProps: 'LayersProps' }),
    ...mapGetters('canvasState', { canvasProps: 'CanvasProps' }),
    ...mapGetters('canvasState', { constants: 'Constants' }),
    ...mapGetters('canvasState', { step: 'Step' }),
    ...mapGetters('canvasState', { Progress: 'Progress' }),
    ...mapGetters('canvasState', { stepName: 'stepName' }),
    ...mapGetters('canvasState', { stepNameComplete: 'stepNameComplete' }),
    ...mapGetters('canvasState', { userPicture: 'userPicture' }),
    ...mapGetters('canvasState', { selectedCard: 'SelectedCard' }),
  },
  beforeCreate() {
    this.isSaving = false;
    this.isLoading = false;
  },
  mounted() {
    this.begin();
  },
  watch: {
    layersConfig: {
      deep: true,
      handler() {
        this.saveToLocal();
      },
    },
    selectedCardConfig: {
      deep: true,
      handler() {
        this.saveToLocal();
      },
    },
  },
  methods: {
    ...mapActions('canvasState', ['updateLayer', 'setStep', 'setUserPicture', 'triggerStoreLocal', 'moveStep', 'removePicture', 'clearLoading', 'fileToBase64']),
    saveToLocal() {
      if (!this.isSaving) {
        this.isSaving = true;
        setTimeout(() => {
          this.triggerStoreLocal();
          this.isSaving = false;
        }, 3000);
      }
    },
    begin() {
      setTimeout(() => {
        this.clearLoading();
      }, 3000);
      // console.log('being');
      // const tempImage = this.constants.defaultBackPic;
      // const image = new Image();
      // image.src = tempImage;

      // // adjust picture aspect ratio and lod new picture
      // image.addEventListener('load', () => {
      //   this.addLayer(this.constants.defaultIndexBack, tempImage);
      //   this.setScale(this.constants.defaultIndexBack, image.width, image.height);
      // });

      // // this.addLayer(this.constants.defaultIndexBack, this.constants.defaultBackPic);
      // //  this.layers[this.constants.defaultIndexBack].scale = 0.465;
    },
    uploadPicture() {
      document.getElementById('UploadUserPicture').click();
    },
    uploadMaterial() {
      document.getElementById('UploadUserMaterial').click();
    },
    uploadLogo() {
      document.getElementById('UploadUserLogo').click();
    },
    nextStep() {
      this.setStep(this.step + 1);
    },
    prevStep() {
      this.setStep(this.step - 1);
    },
    loadFile(event) {
      const vm = this;
      let layerUploadPicture = vm.constants.defaultIndexPic;
      // const tempImage = URL.createObjectURL(event.target.files[0]);
      // const image = new Image();
      // image.src = tempImage;

      // // adjust picture aspect ratio and lod new picture
      // image.addEventListener('load', () => {
      //   vm.addLayer(this.constants.defaultIndexPic, tempImage, image);
      //   vm.setScale(this.constants.defaultIndexPic);
      // });
      // this.userPic = tempImage;
      if (this.selectedCard.pictureOnTop) {
        layerUploadPicture = vm.constants.defaultIndexPicOnTop;
      } else {
        layerUploadPicture = vm.constants.defaultIndexPic;
      }

      this.loadImage(event.target.files[0], layerUploadPicture, true, 'Picture', 'output');
    },
    loadMaterial(event) {
      const vm = this;
      // const object = this.layersProps.Materials[vm.constants.defaultIndexAddon];
      const dafultConf = this.layersProps.MaterialProps;
      const image = new Image();
      const file = event.target.files[0];
      const reader = new FileReader();

      // get relation of ratio for materials

      if (typeof file !== 'undefined') {
        const imageProps = {
          height: dafultConf.height,
          width: dafultConf.width,
          defaultX: dafultConf.defaultX,
          defaultY: dafultConf.defaultY,
        };

        reader.onload = function (e) {
          image.src = e.target.result;
        };
        reader.onerror = function (error) {
          alert(error);
        };
        reader.readAsDataURL(file);

        image.addEventListener('load', () => {
          // resize picture
          const canvasResize = document.createElement('canvas');

          // this code will keep relative visual
          // const maxSize = 544; // TODO : pull max size from a site config
          // let { width } = image;
          // let { height } = image;

          // if (width > height) {
          //   if (width > maxSize) {
          //     height *= maxSize / width;
          //     width = maxSize;
          //   }
          // } else if (height > maxSize) {
          //   width *= maxSize / height;
          //   height = maxSize;
          // }

          // forcing picture to desired values
          canvasResize.width = dafultConf.width;
          canvasResize.height = dafultConf.height;
          canvasResize.getContext('2d').drawImage(image, 0, 0, dafultConf.width, dafultConf.height);
          const dataUrl = canvasResize.toDataURL('image/jpeg');
          // const resizedImage = dataURLToBlob(dataUrl);
          // resize picture

          // const ratio = dafultConf.width / image.width;
          const ratio = 1;
          vm.addLayer(this.constants.defaultIndexAddon, dataUrl, 'Material', imageProps);
          this.layers[this.constants.defaultIndexAddon].scale = ratio;
        });
      }
    },
    loadLogo(event) {
      const vm = this;
      // const object = this.layersProps.Materials[vm.constants.defaultIndexAddon];
      // const dafultConf = this.layersProps.MaterialProps;
      const image = new Image();
      const file = event.target.files[0];
      const reader = new FileReader();

      // get relation of ratio for materials

      if (typeof file !== 'undefined') {
        // const imageProps = {
        //   height: dafultConf.height,
        //   width: dafultConf.width,
        //   defaultX: dafultConf.defaultX,
        //   defaultY: dafultConf.defaultY,
        // };

        reader.onload = function (e) {
          image.src = e.target.result;
        };
        reader.onerror = function (error) {
          alert(error);
        };
        reader.readAsDataURL(file);

        image.addEventListener('load', () => {
          // const ratio = dafultConf.width / image.width;
          vm.addLayer(this.constants.defaultIndexTeamLogo, image.src, 'Team', image);
          // this.layers[this.constants.defaultIndexTeamLogo].scale = ratio;
          this.layers[this.constants.defaultIndexTeamLogo].alpha = 1;
        });
      }
    },
    loadImage(fileSRC, layer, scale, LayerName, previewObj, defaultPic) {
      const vm = this;
      const image = new Image();
      const file = fileSRC;
      const reader = new FileReader();

      if (typeof file !== 'undefined') {
        reader.onload = function (e) {
          image.src = e.target.result;
        };
        reader.onerror = function (error) {
          alert(error);
        };
        reader.readAsDataURL(file);

        image.addEventListener('load', () => {
          if (layer >= 0) { vm.addLayer(layer, image.src, LayerName, image); }
          if (scale) { vm.setScale(layer, image.width, image.height); }
          if (previewObj) { document.getElementById(previewObj).src = image.src; }
          if (defaultPic) { this.setDefaultPicture(image.src); }
          vm.setUserPicture(vm.layers[vm.canvasProps.movingLayer]);
        });
      }
    },
    addLayer(index, file, LayerName, image) {
      // const setCursor = (this.constants.defaultIndexPic === index
      //   || this.constants.defaultIndexPicExtra === index)
      //   ? 'move' : '';
      const newLayer = {
        animation: 'stand',
        x: typeof image.defaultX === 'undefined' ? 0 : image.defaultX,
        y: typeof image.defaultY === 'undefined' ? 0 : image.defaultY,
        flip: 'horizontal',
        rotation: 0,
        moving: null,
        target: null,
        scale: 1,
        file,
        cursor: 'move',
        width: image.width,
        height: image.height,
        LayerName,
      };
      this.layers.splice(index, 1, newLayer);
    },
    setInitialMovement(payload) {
      this.mousePosition = payload;
      this.picPosition.x = this.layers[this.canvasProps.movingLayer].x;
      this.picPosition.y = this.layers[this.canvasProps.movingLayer].y;
      // console.log(`initial position X:${payload.stageX} Y:${payload.stageY}`);
    },
    setScale(index) {
      let scalePercent = 0.0;
      if (this.selectedCard.Orientation === 'H') {
        scalePercent = this.selectedCard.height / this.layers[index].height;
      } else {
        scalePercent = this.selectedCard.width / this.layers[index].width;
      }

      this.layers[index].scale = scalePercent;
    },
    setScaleRelative(index) {
      let scalePercent = 0.0;
      if (this.selectedCard.Orientation === 'H') {
        scalePercent = this.selectedCard.height / this.layers[index].height;
      } else {
        scalePercent = this.selectedCard.width / this.layers[index].width;
      }

      this.layers[index].scale = scalePercent;
    },
    setAccent(index) {
      const object = this.layersProps.Accents[index];
      const image = {
        height: object.height,
        width: object.width,
      };
      this.addLayer(this.constants.defaultIndexAccent, object.File, 'Accent', image);
      this.setScale(this.constants.defaultIndexAccent);
      this.fileToBase64({ index: this.constants.defaultIndexAccent, file: object.File });
    },
    setTeamLogo(index) {
      const object = this.layersProps.Logos[index];
      const image = {
        height: object.height,
        width: object.width,
      };
      this.addLayer(this.constants.defaultIndexTeamLogo, object.File, 'TeamLogo', image);
      this.setScale(this.constants.defaultIndexTeamLogo);
      this.fileToBase64({ index: this.constants.defaultIndexTeamLogo, file: object.File });
    },
    setMaterial(index) {
      const object = this.layersProps.Materials[index];
      const dafultConf = this.layersProps.MaterialProps;

      // get relation of ratio for materials
      const ratio = dafultConf.width / object.width;

      const image = {
        height: dafultConf.height,
        width: dafultConf.width,
        defaultX: dafultConf.defaultX,
        defaultY: dafultConf.defaultY,
      };
      this.addLayer(this.constants.defaultIndexAddon, object.File, 'Material', image);
      this.layers[this.constants.defaultIndexAddon].scale = ratio;
      this.fileToBase64({ index: this.constants.defaultIndexAddon, file: object.File });
      // this.setScale(this.constants.defaultIndexAddon);
    },
    centerTextBack() {
      const text = this.selectedCard.backOfCardDefaultText;
      const lines = text.split(/\r|\r\n|\n/);
      const count = lines.length;

      this.selectedCard.backOfCardTextX = this.selectedCard.width / 2;
      const blockSize = count * (this.selectedCard.backOfCardSize * 1);
      this.selectedCard.backOfCardTextY = ((this.selectedCard.height - 70) - (blockSize)) / 2;
    },
    moving(payload) {
      if (this.layers[this.canvasProps.movingLayer] && payload.type === 'pressmove' && !this.isMoving && this.canvasProps.allowMovement) {
        this.isMoving = true;

        this.movePicture(payload.stageX - this.mousePosition.stageX,
          payload.stageY - this.mousePosition.stageY);
      } else {
        this.isMoving = false;
      }
    },
    movePicture(posX, posY) {
      const object = this.layers[this.canvasProps.movingLayer];
      object.x = this.picPosition.x + posX;
      object.y = this.picPosition.y + posY;

      setTimeout(() => {
        this.isMoving = false;
      }, 100);
    },
    zoom(parm) {
      const object = this.layers[this.canvasProps.movingLayer];
      if (object) {
        if (parm === '+') {
          object.scale += 0.01;
        } else {
          object.scale -= 0.01;
        }
      }
    },
    reset() {
      const object = this.layers[this.canvasProps.movingLayer];
      this.setScale(this.canvasProps.movingLayer);
      object.x = 0;
      object.y = 0;
      object.rotation = 0;
    },
    rotate(parm) {
      const object = this.layers[this.canvasProps.movingLayer];
      if (object) {
        if (parm === '-') {
          object.rotation += 2;
          object.y -= object.width / 110;
          object.x += object.height / 100;
        } else {
          object.rotation -= 2;
          object.y += object.width / 110;
          object.x -= object.height / 100;
        }
      }
    },
    downloadCard() {
      const btn = document.getElementById('downloadButton');
      const image = document.getElementById('cardBuilder')
        .toDataURL('image/png;base64')
        .replace('image/png', 'image/octet-stream');

      btn.href = image;
      btn.crossorigin = 'anonymous';
      btn.download = '1of1 Custom Card.png';
    },
  },
};

export default userPicture;

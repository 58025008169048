<template>
  <v-container id="carousel">
    <v-row class="text-center carousel__container"  align="center" justify="center">
      <v-col
        v-for="item in allTemplates"
        :key="item.Key"
        class="d-flex child-flex"
        cols="12"
        sm="4"
        xd="4"
        md="3">
          <CardTemplate
            :cardKey="item.Key"
            :title='item.Title'
            :image-lazy="`https://picsum.photos/10/6?image=1`"
            :image-src="item.Template"
            @onClick="setSelectedCard({ key: item.Key, 'type': 'user' })"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<style>
  @import '../css/templates.css';
</style>
<script>
import templates from '@/mixins/templates';
import CardTemplate from '@/components/general/CardTemplate.vue';

export default {
  name: 'templates',
  components: {
    CardTemplate,
  },
  mixins: [templates],
  data() {
    return {};
  },
};
</script>

<template>
  <v-container>
    <v-row class="text-center"
        cols="11"
        sm="11"
        md="11">
      <v-col
        cols="12"
        sm="12"
        md="12">
        <v-card
            v-if="stepConfig===1"
            class="pa-2"
            height="100%">
              <v-card-title class="headline">
              Setup Card Templates
              <v-btn
                  class="mx-2"
                  dark
                  color="primary"
                  @click="setConfig(2)">
                  Create new card
                </v-btn>
            </v-card-title>
            <v-card-subtitle align="left">
              Select your Card
            </v-card-subtitle>
              <TemplatesComp v-bind="$props"/>
        </v-card>
        <v-card
            v-if="stepConfig===2"
            class="pa-2"
            height="100%">
              <v-card-title class="headline">
              Setup Template
            </v-card-title>
            <v-card-subtitle align="left">
              Update details
            </v-card-subtitle>
              <SetupComp v-bind="$props"/>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SetupComp from '@/components/Setup.vue';
import TemplatesComp from '@/components/ListTemplatesConfig.vue';
import { mapGetters, mapActions } from 'vuex';
// import { mapGetters, mapActions } from 'vuex';

export default {
  components: { SetupComp, TemplatesComp },
  name: 'Configuration',
  mixins: [],

  data: () => ({
  }),
  methods: {
    ...mapActions('canvasState', ['setConfig', 'resetConfig']),
    mounted() {
    },
    startNew() {
      this.resetConfig();
      this.setConfig(2);
    },
  },
  computed: {
    ...mapGetters('canvasState', { stepConfig: 'StepConfig' }),
  },
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-2",attrs:{"height":"100%","tile":"","outlined":""}},[_c('v-row',{staticClass:"text-center mt-1",attrs:{"cols":"10","sm":"10","md":"10"}},[_c('v-col',[_c('v-btn',{attrs:{"elevation":"2","large":"","raised":""},on:{"click":_vm.addLogos}},[_vm._v(" Add Logo")]),_c('p'),(_vm.layersConfigProps.Logos.length > 0)?[_vm._l((_vm.layersConfigProps.Logos),function(accent,Index){return [_c('v-row',{key:'R'+Index},[_c('v-col',{key:'A'+Index},[_c('v-text-field',{key:Index,attrs:{"label":"Enter Logo Name","hide-details":"auto"},model:{value:(_vm.layersConfigProps.Logos[Index].Title),callback:function ($$v) {_vm.$set(_vm.layersConfigProps.Logos[Index], "Title", $$v)},expression:"layersConfigProps.Logos[Index].Title"}})],1),_c('v-col',{key:'BM'+Index,attrs:{"cols":"12","sm":"2","md":"2"}},[(accent.Title != '')?_c('v-btn',{key:Index,attrs:{"elevation":"2","color":"success","outlined":""},on:{"click":function($event){return _vm.uploadTeamLogo(Index)}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-upload")])]}}],null,true)},[_c('span',[_vm._v("Upload Logo")])])],1):_vm._e()],1),_c('v-col',{key:'CM'+Index,attrs:{"cols":"12","sm":"2","md":"2"}},[(accent.Title != '')?_c('v-btn',{key:Index,attrs:{"color":"primary","elevation":"2","outlined":""},on:{"mouseover":function($event){return _vm.showLogo(Index)},"mouseleave":function($event){return _vm.hideLogo(Index)}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-eye")])]}}],null,true)},[_c('span',[_vm._v("Preview Logo")])])],1):_vm._e(),_c('img',{staticClass:"imageHoverShow",attrs:{"id":'logoPreviewImg'+Index,"src":accent.File}})],1),_c('v-col',{key:'DM'+Index,attrs:{"cols":"12","sm":"2","md":"2"}},[(accent.Title != '')?_c('v-btn',{key:Index,attrs:{"elevation":"2","raised":"","color":"error"},on:{"click":function($event){return _vm.removeLogo(Index)}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-delete")])]}}],null,true)},[_c('span',[_vm._v("Delete Material")])])],1):_vm._e()],1),_c('input',{staticClass:"hidden",staticStyle:{"display":"none"},attrs:{"id":'uploadTeamLogo' + Index,"type":"file","accept":".jpeg, .jpg, .png"},on:{"change":function($event){return _vm.loadTeamLogo($event, Index)}}})],1)]})]:_vm._e()],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
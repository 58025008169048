<template>
    <v-main class="grey lighten-3">
      <v-container id="MainContainer">
        <v-row>
          <v-col>
            <div class="Canvas">
              <CanvasComp/>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
</template>

<script>
import CanvasComp from '@/components/Canvas.vue';

export default {
  name: 'CanvasView',
  components: {
    CanvasComp,
  },
};
</script>

<template>
  <v-main class="grey lighten-3">
      <v-container>
        <v-row>
          <v-col>
            <ConfigComp/>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
</template>

<script>
import ConfigComp from '@/components/Configuration.vue';

export default {
  name: 'ConfigurationView',
  components: {
    ConfigComp,
  },
};
</script>

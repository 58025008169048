<template>
  <v-container
    class="pa-2"
    height="100%">
    <easel-canvas
        class="canvasMain"
        :width="SelectedCardSetup.width"
        :height="SelectedCardSetup.height"
        id="cardBuilder">
      <easel-shape
          form="rect"
          :dimensions="[SelectedCardSetup.width,SelectedCardSetup.height]"
          :x="0"
          :y="0"
          fill="#f7f7f7">
      </easel-shape>
      <template v-for="(value, name, index) in LayersPreview">
        <easel-bitmap
          v-if="value.LayerName !== 'Shape'"
          @mousedown="handleDown($event)"
          @pressmove="handleMove($event)"
          v-bind:key="index"
          :rotation="value.rotation"
          :image="value.file"
          :layer="30+index"
          :x="value.x"
          :y="value.y"
          :scale="value.scale"
          :alpha="value.alpha"
          :cursor="value.cursor"
          :align="['left','top']">
        </easel-bitmap>
        <easel-shape
          v-if="value.LayerName === 'Shape' && SelectedCardSetup.showShapeBehindLogo"
          form="rect"
          v-bind:key="'S'+index"
          :dimensions="[(LayersPreview[constants.defaultIndexTeamLogo].width)*
          LayersPreview[constants.defaultIndexTeamLogo].scale+20,
          LayersPreview[constants.defaultIndexTeamLogo].height*
          LayersPreview[constants.defaultIndexTeamLogo].scale+20,10]"
          :x="LayersPreview[constants.defaultIndexTeamLogo].x-10"
          :y="LayersPreview[constants.defaultIndexTeamLogo].y-10"
          :fill="SelectedCardSetup.showShapeColor">
        </easel-shape>
      </template>
      <template v-for="(value, name, index) in LayersPropsPreview.textLayers">
      <easel-text
              :align="'top-'+value.Align"
              :text="value.Text"
              :rotation="value.Flip"
              :key="index"
              :shadow="['#969090',2,2,9]"
              :color="value.Color"
              :font="adjustFontSize(value.Text, value) + 'px ' + SelectedCardSetup.Font"
              :x="value.posX"
              :y="value.posY"
              scale="1">
        </easel-text>
      </template>
      <template v-if="(typeof LayersMaterialPrev) !== 'undefined'">
        <template v-if="LayersMaterialPrev.showPrev === true">
          <easel-shape
              form="rect"
              :dimensions="[LayersMaterialPrev.width,LayersMaterialPrev.height]"
              :x=LayersMaterialPrev.defaultX
              :y=LayersMaterialPrev.defaultY
              fill="#2196f3bf">
          </easel-shape>
        </template>
      </template>
    </easel-canvas>
  </v-container>
</template>

<style>
  @import '../../css/canvas.css';
</style>;

<script>
import setup from '@/mixins/setup';

export default {
  name: 'SetupPreview',
  data: () => ({
    emitMove: false,
    emitClick: false,
  }),
  mixins: [setup],
  methods: {
    handleMove(e) {
      const vm = this;
      // console.log(e);
      if (this.emitMove) {
        this.$emit('pressmove', e);
        this.emitMove = false;
      }
      setTimeout(() => { vm.emitMove = true; }, 300);
    },
    handleDown(e) {
      // console.log(e);
      this.$emit('mousedown', e);
    },
  },
  props: {
    LayersPropsPreview: {
      type: Object,
      default: () => {},
    },
    LayersPreview: {
      type: Array,
      default: () => [],
    },
    LayersMaterialPrev: {
      type: Object,
      default: () => {},
    },
    SelectedCardSetup: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<template>
  <v-card
    class="pa-2"
    height="100%"
    tile
    outlined>
    <v-row
      class="text-center mt-1"
      cols="10"
      sm="10"
      md="10">
        <v-col>
          <v-btn
            elevation="2"
            large
            raised
            @click="addMaterials">
            Add Materials</v-btn>
          <p></p>
          <v-expansion-panels inset>
            <v-expansion-panel>
              <v-expansion-panel-header>Configuration</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="12"
                          sm="12"
                          md="9">
                    <v-slider
                      label="Position X"
                      v-model="layersConfigProps.MaterialProps.defaultX"
                      :max="canvasProps.width"
                      min="0"></v-slider>
                  </v-col>
                    <v-col>
                        <v-text-field
                          type="number"
                          label="Material Width"
                          v-model="layersConfigProps.MaterialProps.width"
                          suffix="px"
                          hide-details="auto">
                          </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12"
                          sm="12"
                          md="9">
                      <v-slider
                        label="Position Y"
                        v-model="layersConfigProps.MaterialProps.defaultY"
                        :max="canvasProps.width"
                        min="0"></v-slider>
                    </v-col>
                    <v-col>
                        <v-text-field
                          :max="canvasProps.width"
                          type="number"
                          label="Material Height"
                          v-model="layersConfigProps.MaterialProps.height"
                          suffix="px"
                          hide-details="auto">
                          </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12"
                          sm="6"
                          md="6">
                      <v-checkbox
                        v-model="layersConfigProps.MaterialProps.showPrev"
                        label="Show guide box for material"
                        color="primary"
                      ></v-checkbox>
                    </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-divider role="presentation" class="mb-5 mt-5"></v-divider>
          <template v-if="layersConfigProps.Materials.length > 0" >
            <template v-for="(material, Index) in layersConfigProps.Materials">
              <v-row  :key="'RM'+Index">
                <v-col :key="'AM'+Index"
                    cols="12"
                    sm="6"
                    md="6">
                  <v-text-field
                    :key="Index"
                    label="Enter material Name"
                    v-model="layersConfigProps.Materials[Index].Title"
                    hide-details="auto">
                    </v-text-field>
                </v-col>
                <v-col :key="'BM'+Index"
                      cols="12"
                      sm="2"
                      md="2">
                      <v-btn
                        v-if="material.Title != ''"
                        :key="Index"
                        elevation="2"
                        color="success"
                        outlined
                        @click="uploadMaterials(Index)">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                            v-bind="attrs"
                            v-on="on">mdi-upload</v-icon>
                          </template>
                          <span>Upload Material</span>
                        </v-tooltip>
                      </v-btn>
                </v-col>
                <v-col
                      :key="'CM'+Index"
                      cols="12"
                      sm="2"
                      md="2">
                      <v-btn
                        v-if="material.Title != ''"
                        :key="Index"
                        color="primary"
                        elevation="2"
                        outlined
                        @mouseover="showPicture(Index)"
                        @mouseleave="hidePicture(Index)">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                            v-bind="attrs"
                            v-on="on">mdi-eye</v-icon>
                          </template>
                          <span>Preview Material</span>
                        </v-tooltip></v-btn>
                   <img :id="'previewImg'+Index" class="imageHoverShow" :src="material.File"/>
                </v-col>
                <v-col
                      :key="'DM'+Index"
                      cols="12"
                      sm="2"
                      md="2">
                      <v-btn
                        v-if="material.Title != ''"
                        :key="Index"
                        elevation="2"
                        raised
                        color="error"
                        @click="removeMaterials(Index)">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                            v-bind="attrs"
                            v-on="on">mdi-delete</v-icon>
                          </template>
                          <span>Delete Material</span>
                        </v-tooltip></v-btn>
                </v-col>
                <input :id="'uploadMaterials' + Index"
                class="hidden"
                style="display: none;"
                type="file"
                accept=".jpeg, .jpg, .png"
                @change="loadMaterial($event, Index)">
              </v-row>
            </template>
          </template>
        </v-col>
    </v-row>
  </v-card>
</template>

<style>
  @import '../../css/imageHover.css';
</style>;

<script>
import setup from '@/mixins/setup';

export default {
  name: 'SetupMaterials',
  mixins: [setup],
};
</script>

// import axios from 'axios';
import { db, storage } from '@/firebase';
import Vue from 'vue';

/* eslint-disable no-shadow */
const state = () => ({
  step: 1,
  Progress: 1,
  userPicture: '',
  stepConfig: 1,
  layers: [],
  layersProps: {
    Key: '',
    textLayers: [],
    Accents: [],
    Materials: [],
  },
  layersConfig: [],
  layersConfigProps: {
    Key: '',
    textLayers: [],
    Accents: [],
    Materials: [],
    Logos: [],
    MaterialProps: {
      height: 0,
      defaultY: 0,
      defaultX: 0,
      width: 0,
      showPrev: true,
    },
  },
  selectedCard: {
  },
  selectedCardConfig: {
    Key: '',
    Title: '',
    Template: '',
    width: '422',
    height: '303',
    Font: 'Roboto',
    Orientation: 'H',
    allowColorSelection: true,
    allowLogoSelection: true,
    allowMaterialSelection: true,
    showShapeBehindLogo: false,
    showShapeColor: '#fffff',
    pictureOnTop: false,
    backOfCard: '',
    backOfCardScale: 1,
    backOfCardDefaultText: 'Thanks for your Purchase!',
    backOfCardAlign: 'center',
    backOfCardFont: 'Chango',
    backOfCardColor: '#000000',
    backOfCardSize: '14',
    backOfCardTextX: '211',
    backOfCardTextY: '110.5',
    backOfCardLink: '',
  },
  canvasProps: {
    width: 422,
    height: 303,
    Orientation: 'H',
    SelectedMaterial: '',
    SelectedLogo: '',
    movingLayer: 1,
    allowMovement: false,
  },
  constants: {
    defaultIndexBack: 0,
    defaultIndexPic: 1,
    defaultIndexPicExtra: 2,
    defaultIndexTemplate: 3,
    defaultIndexAddon: 4,
    defaultIndexPicOnTop: 5,
    defaultIndexAccent: 6,
    defaultIndexLogo: 7,
    defaultIndexTeamLogoShape: 8,
    defaultIndexTeamLogo: 9,
    defaultIndexWaterMark: 10,
    defaultBackPic: '../card-background.png',
  },
  LoadingView: false,
  Templates: [],
  stepName: [
    'Initial', // 0
    'Select Template', // 1
    'Upload Picture', // 2
    'Customize Text', // 3
    'Pick Colors', // 4
    'Pick Logo', // 5
    'Pick Materials', // 6
    'Customize Back', // 7
    'Download', // 8
  ],
  isLoading: true,
});

const getters = {
  isLoading: (state) => state.isLoading,
  state: (state) => state,
  Step: (state) => state.step,
  stepName: (state) => state.stepName,
  Progress: (state) => state.Progress,
  userPicture: (state) => state.userPicture,
  Constants: (state) => state.constants,
  StepConfig: (state) => state.stepConfig,
  Layers: (state) => state.layers,
  LayersProps: (state) => state.layersProps,
  LayersConfig: (state) => state.layersConfig,
  LayersConfigProps: (state) => state.layersConfigProps,
  CanvasProps: (state) => state.canvasProps,
  SelectedCard: (state) => state.selectedCard,
  SelectedCardConfig: (state) => state.selectedCardConfig,
  allTemplates: (state) => state.Templates,
};

const actions = {
  updateLayer({ commit }, layer, index) {
    commit('UPDATE_LAYER', layer, index);
  },
  calculateProgress({ commit }) {
    commit('CALCULATE_PROGRESS');
  },
  setSelectedCard({ commit }, { key, type }) {
    if (type === 'config') {
      commit('SELECTED_CARD', { key, type });
      commit('SET_CONFIG', 2);
    } else if (type === 'user') {
      commit('RESET_CANVAS');
      commit('SELECTED_CARD', { key, type });
      commit('MOVE_STEP', '+');
    }
  },
  defineSteps({ commit }) {
    commit('DEFINE_STEPS');
  },
  saveCard({ commit }) {
    commit('TRIGGER_LOCAL_STORE');
    commit('SAVE_TEMPLATE_GC');
    commit('RESET_CONFIG');
    commit('UPDATE_LIST_OF_TEMPLATES');
  },
  ListOfTemplates({ commit }) {
    commit('UPDATE_LIST_OF_TEMPLATES');
  },
  setSelectedCardConfig({ commit }, card) {
    commit('SELECT_CARD_CONFIG', card);
  },
  setNextStep({ commit }) {
    commit('NEXT_STEP');
  },
  setStep({ commit }, step) {
    commit('SET_STEP', step);
    commit('CALCULATE_PROGRESS');
  },
  moveStep({ commit }, dir) {
    commit('MOVE_STEP', dir);
    // commit('SAVE_IMAGE', dir);
    // commit('GET_IMAGE', 'KMOT6IV8IDRCG8BU5P6F0OFUG6K7A');
  },
  saveImage({ commit }, { data, layer, Type }) {
    commit('SAVE_IMAGE', { data, layer, Type });
  },
  setUserPicture({ commit }, layer) {
    commit('SET_USER_PICTURE', layer);
  },
  setConfig({ commit }, step) {
    commit('SET_CONFIG', step);
  },
  toggleLoadingView({ commit }, loading) {
    commit('TOGGLE_LOADING', loading);
  },
  addTextLayer({ commit }) {
    commit('ADD_TEXT_LAYER_CONFIG');
  },
  removeTextLayer({ commit }, index) {
    commit('REMOVE_TEXT_LAYER_CONFIG', index);
  },
  deleteCard({ commit }, card) {
    commit('DELETE_CARD', card);
  },
  addAccents({ commit }) {
    commit('ADD_ACCENT_LAYER_CONFIG');
  },
  removeAccents({ commit }, index) {
    commit('REMOVE_ACCENT_LAYER_CONFIG', index);
  },
  addMaterials({ commit }) {
    commit('ADD_MATERIAL_LAYER_CONFIG');
  },
  removeMaterials({ commit }, index) {
    commit('REMOVE_MATERIAL_LAYER_CONFIG', index);
  },
  addLogos({ commit }) {
    commit('ADD_LOGOS_LAYER_CONFIG');
  },
  removeLogos({ commit }, index) {
    commit('REMOVE_LOGOS_LAYER_CONFIG', index);
  },
  resetConfig({ commit }) {
    commit('RESET_CONFIG');
  },
  resetCanvas({ commit }) {
    commit('RESET_CANVAS');
  },
  triggerStoreLocal({ commit }) {
    commit('TRIGGER_LOCAL_STORE');
  },
  setDefaultPicture({ commit }, picture) {
    commit('SET_DEFAULT_PIC', picture);
  },
  removePicture({ commit }) {
    commit('REMOVE_PICTURE');
  },
  clearLoading({ commit }) {
    commit('CLEAR_LOADING');
  },
  fileToBase64({ commit }, { index, file }) {
    commit('FILE_TO_BASE64', { index, file });
  },
};

const mutations = {
  CLEAR_LOADING(state) {
    state.isLoading = false;
  },
  RESET_CONFIG(state) {
    state.stepConfig = 1;
    state.selectedCardConfig = {
      Key: '',
      Title: '',
      Template: '',
      width: '422',
      height: '303',
      Font: 'Roboto',
      Orientation: 'H',
      allowColorSelection: true,
      allowLogoSelection: true,
      allowMaterialSelection: true,
      pictureOnTop: false,

      showShapeBehindLogo: false,
      showShapeColor: '#fffff',
      backOfCard: '',
      backOfCardScale: 1,
      backOfCardDefaultText: 'Thanks for your Purchase!',
      backOfCardAlign: 'center',
      backOfCardFont: 'Chango',
      backOfCardColor: '#000000',
      backOfCardSize: '14',
      backOfCardTextX: '211',
      backOfCardTextY: '110.5',
      backOfCardLink: '',

    };
    state.layersConfig = [];
    state.layersConfigProps = {
      Key: '',
      textLayers: [],
      Accents: [],
      Materials: [],
      Logos: [],
      MaterialProps: {
        height: 0,
        defaultY: 0,
        defaultX: 0,
        width: 0,
        showPrev: true,
      },
    };
  },
  RESET_CANVAS(state) {
    state.step = 1;
    state.selectedCard = {
      Key: '',
      Title: '',
      Template: '',
      width: '422',
      height: '303',
      Font: 'Roboto',
      Orientation: 'H',
      allowColorSelection: true,
      allowLogoSelection: true,
      allowMaterialSelection: true,
      pictureOnTop: false,
    };
    state.layers = [];
    state.layersProps = {
      Key: '',
      textLayers: [],
      Accents: [],
      Materials: [],
      Logos: [],
      MaterialProps: {
        height: 0,
        defaultY: 0,
        defaultX: 0,
        width: 0,
        showPrev: true,
      },
    };
  },
  UPDATE_LAYER(state, layer, index) {
    state.layers[index] = layer;
  },
  DEFINE_STEPS(state) {
    state.stepName = [
      'Initial', // 0
      'Select Template', // 1
      'Upload Picture', // 2
      'Customize Text', // 3
      'Pick Colors', // 4
      'Pick Logo', // 5
      'Pick Materials', // 6
      'Customize Back', // 7
      'Download', // 8
    ];

    if (state.selectedCardConfig.Orientation === 'H') {
      state.canvasProps.width = 422;
      state.canvasProps.height = 303;
    } else if (state.selectedCardConfig.Orientation === 'V') {
      state.canvasProps.height = 422;
      state.canvasProps.width = 303;
    }

    if (!state.selectedCard.allowColorSelection) {
      state.stepName = state.stepName.filter((e) => e !== 'Pick Colors');
    }

    if (!state.selectedCard.allowMaterialSelection) {
      state.stepName = state.stepName.filter((e) => e !== 'Pick Materials');
    }

    if (!state.selectedCard.allowLogoSelection) {
      state.stepName = state.stepName.filter((e) => e !== 'Pick Logo');
    }

    if (state.stepName[state.step] === 'Upload Picture' && !state.selectedCard.pictureOnTop) { state.canvasProps.movingLayer = state.constants.defaultIndexPic; }
    if (state.stepName[state.step] === 'Upload Picture' && state.selectedCard.pictureOnTop) { state.canvasProps.movingLayer = state.constants.defaultIndexPicOnTop; }
    if (state.stepName[state.step] === 'Pick Materials') { state.canvasProps.movingLayer = state.constants.defaultIndexAddon; }
  },
  MOVE_STEP(state, dir) {
    if (dir === '+') {
      state.step += 1;
    } else {
      state.step -= 1;
    }

    state.canvasProps.allowMovement = true;
    // setting rules to move pictures
    if (state.stepName[state.step] === 'Upload Picture' && !state.selectedCard.pictureOnTop) {
      state.canvasProps.movingLayer = state.constants.defaultIndexPic;
    } else if (state.stepName[state.step] === 'Upload Picture' && state.selectedCard.pictureOnTop) {
      state.canvasProps.movingLayer = state.constants.defaultIndexPicOnTop;
    } else if (state.stepName[state.step] === 'Pick Materials') {
      state.canvasProps.movingLayer = state.constants.defaultIndexAddon;
      state.canvasProps.allowMovement = false;
    } else if (state.stepName[state.step] === 'Pick Logo') {
      state.canvasProps.movingLayer = state.constants.defaultIndexTeamLogo;
    } else {
      // do not allow to move
      state.canvasProps.allowMovement = false;
    }

    this.dispatch('canvasState/calculateProgress');
  },
  NEXT_STEP(state) {
    state.step += 1;
    this.dispatch('canvasState/calculateProgress');
  },
  CALCULATE_PROGRESS(state) {
    state.Progress = (state.step / (state.stepName.length - 1)) * 100;
  },
  REMOVE_PICTURE(state) {
    state.userPicture = '';
    state.layers.splice(state.constants.defaultIndexPic, 1, '');
    state.layers.splice(state.constants.defaultIndexPicOnTop, 1, '');
  },
  SET_STEP(state, step) {
    state.step = step;
  },
  SET_CONFIG(state, step) {
    state.stepConfig = step;
  },
  SET_USER_PICTURE(state, layer) {
    state.userPicture = JSON.parse(JSON.stringify(layer));
  },
  SELECTED_CARD_CONFIG(state, card) {
    state.selectedCardConfig = card;
  },
  ADD_TEXT_LAYER_CONFIG(state) {
    const text = {
      Index: state.layersConfigProps.textLayers.length,
      Title: '',
      Limit: 20,
      Color: '#000000',
      Size: 20,
      Align: 'left',
      posX: 50,
      posY: 50,
      Flip: 0,
    };

    Vue.set(state.layersConfigProps.textLayers, state.layersConfigProps.textLayers.length, text);
  },
  REMOVE_TEXT_LAYER_CONFIG(state, index) {
    state.layersConfigProps.textLayers.splice(index, 1);
  },
  ADD_ACCENT_LAYER_CONFIG(state) {
    const accent = {
      Index: state.layersConfigProps.Accents.length,
      Title: '',
      File: '',
      scale: 1,
      height: 0,
      width: 0,
      X: 0,
      Y: 0,
    };

    Vue.set(state.layersConfigProps.Accents, state.layersConfigProps.Accents.length, accent);
  },
  REMOVE_ACCENT_LAYER_CONFIG(state, index) {
    state.layersConfigProps.Accents.splice(index, 1);
  },
  ADD_MATERIAL_LAYER_CONFIG(state) {
    const material = {
      Index: state.layersConfigProps.Materials.length,
      Title: '',
      File: '',
      height: 0,
      scale: 1,
      width: 0,
      X: 0,
      Y: 0,
    };

    Vue.set(state.layersConfigProps.Materials, state.layersConfigProps.Materials.length, material);
  },
  REMOVE_MATERIAL_LAYER_CONFIG(state, index) {
    state.layersConfigProps.Materials.splice(index, 1);
  },
  ADD_LOGOS_LAYER_CONFIG(state) {
    const logo = {
      Index: state.layersConfigProps.Logos.length,
      Title: '',
      File: '',
      height: 0,
      scale: 1,
      width: 0,
      X: 0,
      Y: 0,
    };

    Vue.set(state.layersConfigProps.Logos, state.layersConfigProps.Logos.length, logo);
  },
  REMOVE_LOGOS_LAYER_CONFIG(state, index) {
    state.layersConfigProps.Logos.splice(index, 1);
  },
  SET_DEFAULT_PIC(state, picture) {
    state.selectedCardConfig.Template = picture;
  },
  // #region Google API
  TRIGGER_LOCAL_STORE(state) {
    const temp = state.stepConfig;
    state.stepConfig = temp;
  },
  SELECTED_CARD(state, { key, type = 'config' }) {
    const vm = this;
    state.isLoading = true;
    db.ref('/Cards').child(key).get()
      .then((snapshot) => {
        if (snapshot.exists()) {
          if (type === 'config') {
            state.selectedCardConfig = snapshot.val();
          } else if (type === 'user') {
            state.selectedCard = snapshot.val();
          }
        } else {
          console.log('No data available');
        }
      })
      .then(() => {
        if (type === 'user') {
          vm.dispatch('canvasState/defineSteps');
        }
      })
      .then(() => {
        db.ref('/Props').child(key).get()
          .then((snapshot) => {
            if (snapshot.exists()) {
              const temporal = snapshot.val();

              if (typeof temporal.Accents === 'undefined') {
                temporal.Accents = [];
              }
              if (typeof temporal.textLayers === 'undefined') {
                temporal.textLayers = [];
              }
              if (typeof temporal.Materials === 'undefined') {
                temporal.Materials = [];
              }
              if (typeof temporal.Logos === 'undefined') {
                temporal.Logos = [];
              }
              if (typeof temporal.MaterialProps === 'undefined') {
                temporal.MaterialProps = {
                  height: 0,
                  defaultY: 0,
                  defaultX: 0,
                  width: 0,
                  showPrev: true,
                };
              }

              if (type === 'config') {
                state.layersConfigProps = temporal;
              } else if (type === 'user') {
                state.layersProps = temporal;
              }
            } else {
              console.log('No data available');
            }
          })
          .catch((error) => {
            console.error(error);
          });
      })
      .then(() => {
        db.ref('/Layers').child(key).get()
          .then((snapshot) => {
            if (snapshot.exists()) {
              if (type === 'config') {
                state.layersConfig = snapshot.val();
              } else if (type === 'user') {
                state.layers = snapshot.val();
                for (let index = 2; index < state.layers.length; index += 1) {
                  fetch(state.layers[index].file)
                    .then((response) => response.blob())
                    .then((myBlob) => {
                      const reader = new FileReader();
                      reader.readAsDataURL(myBlob);
                      reader.onloadend = function () {
                        const base64data = reader.result;
                        Vue.set(state.layers[index], 'file', base64data);
                      };
                    })
                    .catch((error) => {
                      console.error(error);
                      state.isLoading = false;
                    });

                  // this.dispatch('canvasState/fileToBase64', { index, file:.layers[index].file });
                  if (index === state.layers.length - 1) {
                    state.isLoading = false;
                  }
                }
              }
            } else {
              console.log('No data available');
            }
          })
          .then(() => {
            if (type === 'user') {
              if (!state.selectedCard.pictureOnTop) {
                state.layers.splice(state.constants.defaultIndexPic, 1, state.userPicture);
              } else if (state.selectedCard.pictureOnTop) {
                state.layers.splice(state.constants.defaultIndexPicOnTop, 1, state.userPicture);
              }
            }
          })
          .catch((error) => {
            console.error(error);
          });
      })
      .catch((error) => {
        console.error(error);
        state.isLoading = false;
      });
  },
  FILE_TO_BASE64(state, { index, file }) {
    fetch(file)
      .then((response) => response.blob())
      .then((myBlob) => {
        const reader = new FileReader();
        reader.readAsDataURL(myBlob);
        reader.onloadend = function () {
          const base64data = reader.result;
          Vue.set(state.layers[index], 'file', base64data);
        };
      })
      .catch((error) => {
        console.error(error);
        state.isLoading = false;
      });
  },
  SAVE_TEMPLATE_GC(state) {
    if (state.selectedCardConfig.Key !== '') {
      state.layersConfigProps.Key = state.selectedCardConfig.Key;
      db.ref('/Cards').child(state.selectedCardConfig.Key).update(state.selectedCardConfig);
      db.ref('/Props').child(state.selectedCardConfig.Key).set(state.layersConfigProps);
      db.ref('/Layers').child(state.selectedCardConfig.Key).set(state.layersConfig);
    } else {
      const newCardKey = db.ref('/Cards').push().key;
      state.selectedCardConfig.Key = newCardKey;
      db.ref('/Cards').child(state.selectedCardConfig.Key).update(state.selectedCardConfig);
      db.ref('/Props').child(state.selectedCardConfig.Key).set(state.layersConfigProps);
      db.ref('/Layers').child(state.selectedCardConfig.Key).set(state.layersConfig);
    }
  },
  UPDATE_LIST_OF_TEMPLATES(state) {
    state.isLoading = true;
    db.ref('/Cards').get()
      .then((snapshot) => {
        if (snapshot.exists()) {
          state.Templates = snapshot.val();
          state.isLoading = false;
        } else {
          console.log('No data available');
          state.Templates = [];
          state.isLoading = false;
        }
      })
      .catch((error) => {
        console.error(error);
        state.isLoading = false;
      });
  },
  DELETE_CARD(state, key) {
    db.ref('/Cards').child(key).remove()
      .then(() => {
        console.log('removed');
        db.ref('/Props').child(key).remove()
          .catch((error) => {
            console.error(error);
          });
        db.ref('/Layers').child(key).remove()
          .catch((error) => {
            console.error(error);
          });
        storage.ref(key).listAll().then((result) => {
          result.items.forEach((file) => {
            file.delete();
          });
        }).catch((error) => {
          console.error(error);
        });
      })
      .then(() => {
        this.dispatch('canvasState/ListOfTemplates');
      })
      .catch((error) => {
        console.error(error);
      });
  },
  SELECT_CARD_CONFIG(state, key) {
    db.ref('/Cards').child(key).get()
      .then((snapshot) => {
        if (snapshot.exists()) {
          state.selectedCardConfig = snapshot.val();
        } else {
          console.log('No data available');
        }
      })
      .catch((error) => {
        console.error(error);
      });
    db.ref('/Props').child(key).get()
      .then((snapshot) => {
        if (snapshot.exists()) {
          const temporal = snapshot.val();

          if (typeof temporal.Accents === 'undefined') {
            temporal.Accents = [];
          }
          if (typeof temporal.textLayers === 'undefined') {
            temporal.textLayers = [];
          }
          if (typeof temporal.Materials === 'undefined') {
            temporal.Materials = [];
          }
          if (typeof temporal.Logos === 'undefined') {
            temporal.Logos = [];
          }
          if (typeof temporal.MaterialProps === 'undefined') {
            temporal.MaterialProps = {
              height: 0,
              defaultY: 0,
              defaultX: 0,
              width: 0,
              showPrev: true,
            };
          }

          state.layersConfigProps = temporal;
        } else {
          console.log('No data available');
        }
      })
      .catch((error) => {
        console.error(error);
      });

    db.ref('/Layers').child(key).get()
      .then((snapshot) => {
        if (snapshot.exists()) {
          state.layersConfig = snapshot.val();
        } else {
          console.log('No data available');
        }
      })
      .catch((error) => {
        console.error(error);
      });
  },
  SAVE_IMAGE(state, { data, layer, Type }) {
    // const vm = this;
    // const uniqueId = Date.now().toString(36).toUpperCase()
    // + Math.random().toString(36).substring(2).toUpperCase()
    // + Math.random().toString(36).substring(2).toUpperCase();
    state.isLoading = true;

    if (state.selectedCardConfig.Key === '') {
      const newCardKey = db.ref('/Cards').push().key;
      state.selectedCardConfig.Key = newCardKey;
    }

    if (data !== '') {
      storage.ref(`${state.selectedCardConfig.Key}`).child(`${Type}-${layer}`).putString(data, 'data_url')
        .then((snapshot) => {
          snapshot.ref.getDownloadURL()
            .then((URL) => {
              console.log(URL);
              state.isLoading = false;
              switch (Type) {
                case 'DefaultPicture':
                  Vue.set(state.selectedCardConfig, 'Template', URL);
                  break;
                case 'backOfCard':
                  Vue.set(state.selectedCardConfig, 'backOfCard', URL);
                  break;
                case 'Layers':
                  Vue.set(state.layersConfig[layer], 'file', URL);
                  break;
                case 'Colors':
                  Vue.set(state.layersConfigProps.Accents[layer], 'File', URL);
                  break;
                case 'Materials':
                  Vue.set(state.layersConfigProps.Materials[layer], 'File', URL);
                  break;
                case 'Logos':
                  Vue.set(state.layersConfigProps.Logos[layer], 'File', URL);
                  break;
                default:
                  break;
              }
            });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  },
  GET_IMAGE(state, id) {
    console.log(id);
    storage.ref(`Pictures/${id}`).getDownloadURL()
      .then((url) => {
        console.log(url);
      })
      .catch((error) => {
        console.error(error);
      });
  },
  // #endregion
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

<template>
  <v-container id="carousel">
    <v-row class="text-center carousel__container"  align="center" justify="center">
      <v-col
        v-for="item in allTemplates"
        :key="item.Key"
        class="d-flex child-flex"
        cols="12"
        sm="3"
        xd="4"
        md="2">
          <CardTemplate
          :title="item.Title"
          :image-lazy="item.Template"
          :deleteCard=true
          :image-src="item.Template"
          @onClickDelete="deleteCard(item.Key)"
          @onClick="setSelectedCard({ key: item.Key, 'type': 'config' })"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<style>
  @import '../css/templates.css';
</style>
<script>
import templates from '@/mixins/templates';
import CardTemplate from '@/components/general/CardTemplate.vue';

export default {
  name: 'templates',
  components: {
    CardTemplate,
  },
  mixins: [templates],
};
</script>

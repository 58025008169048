<template>
  <v-card-actions class="mt-5">
    <v-btn
      elevation="2"
      color="primary"
      @click="saveCard"
    >Save Template</v-btn>
    <v-btn
      elevation="2"
      color="error"
      @click="resetConfig"
    >Cancel</v-btn>
  </v-card-actions>
</template>

<script>

import setup from '@/mixins/setup';

export default {
  name: 'CardActions',
  mixins: [setup],
};
</script>

<template>
  <v-container>
      <v-row class="text-center"
          cols="12"
          sm="12"
          md="12">
            <v-col
            cols="12"
            sm="6"
            md="6">
            <v-tabs
              v-model="tab"
              grow>
              <v-tab key="General">
                Initial Information
              </v-tab>
              <v-tab key="Upload">
                Upload Content
              </v-tab>
              <v-tab key="Back">
                Back
              </v-tab>
              <v-tab key="Text">
                Texts
              </v-tab>
              <v-tab key="Accents">
                Colors
              </v-tab>
              <v-tab key="Materials">
                Materials
              </v-tab>
              <v-tab key="Logos">
                Logos
              </v-tab>
            </v-tabs>
              <v-tabs-items v-model="tab">
                <v-tab-item key="General">
                      <SetupGeneral/>
                </v-tab-item>
                <v-tab-item key="Upload">
                      <SetupUpload/>
                </v-tab-item>
                <v-tab-item key="Back">
                      <SetupBack/>
                </v-tab-item>
                <v-tab-item key="Text">
                      <SetupText/>
                </v-tab-item>
                <v-tab-item key="Accents">
                      <SetupAccents/>
                </v-tab-item>
                <v-tab-item key="Materials">
                      <SetupMaterials/>
                </v-tab-item>
                <v-tab-item key="Logos">
                      <SetupLogos/>
                </v-tab-item>
              </v-tabs-items>
            </v-col>
        <v-col
          cols="12"
          sm="6"
          md="6">
          <SetupPreview
            :LayersPreview='layersConfig'
            :LayersPropsPreview='layersConfigProps'
            :LayersMaterialPrev='layersConfigProps.MaterialProps'
            :SelectedCardSetup='selectedCardConfig'/>
        </v-col>
      </v-row>

      <input id="UploadTemplate"
      class="hidden"
      style="display: none;"
      type="file"
      accept=".png"
      @change="loadTemplate">

      <input id="UploadLogo"
      class="hidden"
      style="display: none;"
      type="file"
      accept=".png"
      @change="loadLogo">

      <input id="uploadCardBack"
      class="hidden"
      style="display: none;"
      type="file"
      accept=".png"
      @change="loadCardBack">

      <input id="UploadDefault"
      class="hidden"
      style="display: none;"
      type="file"
      accept=".png, .jpg, .jpeg"
      @change="loadDefault">

      <CardActions/>
  </v-container>
</template>

<script>
import setup from '@/mixins/setup';
import SetupUpload from '@/components/setup/Upload.vue';
import SetupText from '@/components/setup/Text.vue';
import SetupBack from '@/components/setup/BackOfCard.vue';
import SetupPreview from '@/components/setup/Preview.vue';
import SetupGeneral from '@/components/setup/General.vue';
import SetupAccents from '@/components/setup/Accents.vue';
import SetupMaterials from '@/components/setup/Materials.vue';
import SetupLogos from '@/components/setup/Logos.vue';
import CardActions from '@/components/setup/CardActions.vue';

export default {
  data: () => ({ tab: '' }),
  components: {
    SetupUpload,
    SetupText,
    SetupBack,
    SetupPreview,
    CardActions,
    SetupGeneral,
    SetupAccents,
    SetupMaterials,
    SetupLogos,
  },
  name: 'Setup',
  mixins: [setup],
};
</script>

<template>
  <v-container min-height="80vh">
    <v-row class="text-center"
        cols="10"
        sm="10"
        md="10">
      <v-col
        v-if="step===1"
        cols="12"
        sm="12"
        md="12">
          <v-card
            class="pa-2"
            height="100%">
              <v-card-title class="headline">
              Select Your Template
            </v-card-title>
              <TemplatesComp v-bind="$props"/>
          </v-card>
      </v-col>
    </v-row>
    <v-row class="text-center"
        cols="12"
        sm="12"
        md="12">
      <v-col>
        <v-progress-linear :value='Progress' v-if="step>=2"></v-progress-linear>
        <v-card
          v-if="step>=2"
          class="pa-5"
          height="100%">
            <v-card-title>
              <v-row>
                    <v-col class="text-left"
                      cols="12"
                      sm="12"
                      md="3">
                      <span class="headline"> Build your card </span> <br>
                      <span> {{ stepName[step] }}
                      </span> <br>
                    </v-col>
                    <v-col cols="12"
                      sm="12"
                      md="9">
                      <v-btn
                        class="mx-2"
                        dark
                        color="primary"
                        @click="moveStep('-')">
                        <v-icon>mdi-arrow-left-bold</v-icon>
                        <span class="d-none d-sm-flex">{{ stepName[step - 1] }}</span>
                        <span class="d-flex d-sm-none">Prev.</span>
                      </v-btn>
                      <v-btn
                        v-if="stepName.length - 1 > step"
                        class="mx-2"
                        dark
                        color="primary"
                        @click="moveStep('+')">
                        <v-icon>mdi-arrow-right-bold</v-icon>
                        <span class="d-none d-sm-flex">{{ stepName[step + 1] }}</span>
                        <span class="d-flex d-sm-none">Next</span>
                      </v-btn>
                    </v-col>
              </v-row>
            </v-card-title>
            <v-row>
              <v-col
                cols="12"
                xs="12"
                sm="12"
                md="6">
                  <Preview
                  v-if="stepName[step]!=='Customize Back'"
                    :LayersPreview='layers'
                    :LayersPropsPreview='layersProps'
                    :SelectedCardSetup='selectedCard'
                    @mousedown="setInitialMovement($event)"
                    @pressmove="moving($event)"/>
                  <PreviewBack
                  v-if="stepName[step]==='Customize Back'"
                    :LayersPreview='layers'
                    :LayersPropsPreview='layersProps'
                    :SelectedCardSetup='selectedCard'
                    @mousedown="setInitialMovement($event)"
                    @pressmove="moving($event)"/>
              </v-col>
              <v-col
                v-if="stepName[step]==='Upload Picture'"
                cols="12"
                xs="12"
                sm="6"
                md="3">
                  <ControlsComp v-bind="$props"/>
              </v-col>
              <v-col
                v-if="stepName[step]==='Upload Picture'"
                cols="12"
                xs="12"
                sm="3"
                md="3">
                  <UploadComp/>
              </v-col>
              <v-col
                v-if="stepName[step]==='Customize Text'"
                cols="12"
                xs="12"
                sm="6"
                md="5">
                  <TextComp/>
              </v-col>
              <v-col
                v-if="stepName[step]==='Pick Colors'"
                cols="12"
                xs="12"
                sm="6"
                md="5">
                  <ColorsComp/>
              </v-col>
              <v-col
                v-if="stepName[step]==='Pick Logo'"
                cols="12"
                xs="12"
                sm="6"
                md="5">
                  <LogosComp/>
              </v-col>
              <v-col
                v-if="stepName[step]==='Customize Back'"
                cols="12"
                xs="12"
                sm="6"
                md="5">
                  <BackOfCard/>
              </v-col>
              <v-col
                v-if="stepName[step]==='Pick Materials'"
                cols="12"
                xs="12"
                sm="6"
                md="5">
                  <MaterialsComp/>
              </v-col>
              <v-col
                v-if="stepName[step]==='Download'"
                cols="12"
                xs="12"
                sm="6"
                md="5">
                  <DownloadComp/>
              </v-col>
            </v-row>
        </v-card>
      </v-col>
    </v-row>

    <input id="UploadUserPicture"
    class="hidden"
    style="display: none;"
    type="file"
    accept=".jpeg, .jpg, .png"
    @change="loadFile">

    <input id="UploadUserMaterial"
    class="hidden"
    style="display: none;"
    type="file"
    accept=".jpeg, .jpg, .png"
    @change="loadMaterial">

    <input id="UploadUserLogo"
    class="hidden"
    style="display: none;"
    type="file"
    accept=".jpeg, .jpg, .png"
    @change="loadLogo">
  </v-container>
</template>

<script>
import userModule from '@/mixins/userModule';
import ControlsComp from '@/components/customize/Controls.vue';
import Preview from '@/components/setup/Preview.vue';
import PreviewBack from '@/components/setup/PreviewBack.vue';
import TemplatesComp from '@/components/Templates.vue';
import UploadComp from '@/components/customize/Upload.vue';
import TextComp from '@/components/customize/Text.vue';
import BackOfCard from '@/components/customize/BackOfCard.vue';
import ColorsComp from '@/components/customize/Colors.vue';
import MaterialsComp from '@/components/customize/Materials.vue';
import DownloadComp from '@/components/customize/Download.vue';
import LogosComp from '@/components/customize/Logos.vue';

export default {
  components: {
    ControlsComp,
    TemplatesComp,
    Preview,
    PreviewBack,
    UploadComp,
    TextComp,
    BackOfCard,
    ColorsComp,
    MaterialsComp,
    LogosComp,
    DownloadComp,
  },
  name: 'CanvasComp',
  mixins: [userModule, ControlsComp],
  methods: {
  },
};
</script>

import Vue from 'vue';
import WebFont from 'webfontloader';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
  beforeCreate() {
    this.$store.commit('initialiseStore');
  },
}).$mount('#app');

store.subscribe((mutation, state) => {
  // Store the state object as a JSON string
  localStorage.setItem('store', JSON.stringify(state));
});

WebFont.load({
  google: {
    families: ['Droid Sans', 'Droid Serif', 'Cabin', 'Libre Baskerville', 'Space Grotesk', 'Bodoni Moda', 'GFS Didot', 'Chango'],
  },
  custom: {
    families: ['Helvetica', 'Helvetica-Bold'],
    urls: ['/fonts.css'],
  },
});

<template>
    <v-card
        class="pa-2"
        height="100%"
        tile
        outlined>
        <v-row
            class="text-center mt-1"
            cols="10"
            sm="10"
            md="10">
            <v-col>
                <v-btn
                    elevation="2"
                    large
                    raised
                    @click="uploadPicture">
                    Select Template</v-btn>
                    <p></p>

                <v-btn
                    v-if="layersConfig[constants.defaultIndexTemplate].file != ''"
                    elevation="2"
                    large
                    raised
                    @click="uploadLogo">
                    Select Logo</v-btn>
                    <p></p>

                <v-btn
                    v-if="layersConfig[constants.defaultIndexTemplate].file != ''"
                    elevation="2"
                    large
                    raised
                    @click="uploadCardBack">
                    Select Back of the Card</v-btn>
                <template v-if="layersConfig[constants.defaultIndexLogo].file != ''">
                    <v-slider
                        label="Size"
                        v-model="layersConfig[constants.defaultIndexLogo].scale"
                        max="1"
                        min="0"
                        step="0.01"></v-slider>
                        {{constants.defaultIndexLogo}}
                    <v-slider
                        :key="'posx'"
                        label="Position X"
                        v-model="layersConfig[constants.defaultIndexLogo].x"
                        :max="canvasProps.width"
                        min="0"></v-slider>
                    <v-slider
                        :key="'posY'"
                        label="Position Y"
                        v-model="layersConfig[constants.defaultIndexLogo].y"
                        :max="canvasProps.width"
                        min="0"></v-slider>
                </template>
                <p></p>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>

import setup from '@/mixins/setup';

export default {
  name: 'SetupUpload',
  mixins: [setup],
};
</script>

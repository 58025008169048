<template>
  <v-app-bar
      app
      color="primary"
      dark
  >
      <div class="d-flex align-center">
        <v-img
          alt="1 of 1 Logo"
          class="shrink mr-2"
          contain
          src="https://static.wixstatic.com/media/50dd39_54e744d17ea94fbf96d3b6a02d767e72~mv2.png/v1/fill/w_105,h_97,al_c,q_85,usm_0.66_1.00_0.01/ICON%20A-01-01.webp"
          transition="scale-transition"
          width="80"
        />
        <span class="mr-2 title">One Of One, One Of A Kind</span>
      </div>

      <v-spacer></v-spacer>
    </v-app-bar>
</template>

<script>
export default {
  name: 'Menu',

  data: () => ({
  }),
};
</script>

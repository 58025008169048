<template>
  <v-container>
      <div class="transition-swing text-h4 mb-8 mt-4 d-none d-sm-flex">Select your picture</div>
      <v-btn
        elevation="2"
        large
        raised
        color='success'
        class="white--text"
        @click="uploadPicture">
        Upload Picture</v-btn>
        <v-btn
        elevation="2"
        large
        raised
        color='error'
        class="white--text mt-3"
        @click="removePicture">
        Remove Picture</v-btn>
    <p><img id="output" class="mt-8" width="200" hidden/></p>
  </v-container>
</template>

<script>

import userPicture from '@/mixins/userModule';

export default {
  name: 'CustomizeUpload',
  mixins: [userPicture],
};
</script>

import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';

Vue.use(require('vue-easeljs'));

const userPicture = {
  data() {
    return {
      isMoving: false,
      isLoading: false,
      isSaving: false,
      isValid: true,
      rules: {
        email: (v) => !!(v === '' ? '@' : v || '').match(/@/) || 'Please enter a valid email',
        length: (len) => (v) => (v || '').length >= len || `Invalid character length, required ${len}`,
        maxLength: (len) => (v) => (v || '').length <= len || `Límite de caracteres (${len})`,
        required: (v) => !!v || 'This field is required',
        isNumber: (value) => {
          // eslint-disable-next-line no-new-wrappers
          const numero = new Number(value);

          return numero instanceof Number || 'Campo debe ser un número';
        },
      },
      mousePosition: {},
      picPosition: {},
      userPic: '',
      userName: '',
    };
  },
  computed: {
    ...mapGetters('canvasState', { canvasProps: 'CanvasProps' }),
    ...mapGetters('canvasState', { constants: 'Constants' }),
    ...mapGetters('canvasState', { layersConfig: 'LayersConfig' }),
    ...mapGetters('canvasState', { layersConfigProps: 'LayersConfigProps' }),
    ...mapGetters('canvasState', { selectedCardConfig: 'SelectedCardConfig' }),
    ...mapGetters('canvasState', { selectedCard: 'SelectedCard' }),
  },
  beforeCreate() {
    setTimeout(() => {
      this.clearLoading();
    }, 3000);
  },
  mounted() {
    this.begin();
  },
  created() {
  },
  methods: {
    ...mapActions('canvasState', ['addTextLayer', 'removeTextLayer', 'resetConfig',
      'saveCard', 'triggerStoreLocal', 'setDefaultPicture', 'setConfig',
      'addAccents', 'removeAccents', 'addMaterials', 'removeMaterials',
      'addLogos', 'removeLogos', 'saveImage', 'clearLoading']),
    begin() {
      if (this.layersConfig.length === 0) {
        const tempImage = this.constants.defaultBackPic;
        const image = new Image();
        image.src = tempImage;

        // Initialize layers
        // adjust picture aspect ratio and lod new picture
        image.addEventListener('load', () => {
          this.addLayer(this.constants.defaultIndexBack, tempImage, 'Base');
          this.setScale(this.constants.defaultIndexBack, image.width, image.height);

          this.addLayer(this.constants.defaultIndexPic, '', 'Picture');
          this.addLayer(this.constants.defaultIndexPicExtra, '', 'PictureExtra');
          this.addLayer(this.constants.defaultIndexTemplate, '', 'Template');
          this.addLayer(this.constants.defaultIndexAddon, '', 'Addon');
          this.addLayer(this.constants.defaultIndexPicOnTop, '', 'PictureOnTop');
          this.addLayer(this.constants.defaultIndexAccent, '', 'Accent');
          this.addLayer(this.constants.defaultIndexLogo, '', 'Logo');
          this.addLayer(this.constants.defaultIndexTeamLogoShape, '', 'Shape');
          this.addLayer(this.constants.defaultIndexTeamLogo, '', 'Team');
          this.addLayer(this.constants.defaultIndexWaterMark, '', 'Water');
        });
      }
      // this.addLayer(this.constants.defaultIndexBack, this.constants.defaultBackPic);
      //  this.layers[this.constants.defaultIndexBack].scale = 0.465;
    },
    uploadPicture() {
      document.getElementById('UploadTemplate').click();
    },
    uploadLogo() {
      document.getElementById('UploadLogo').click();
    },
    uploadCardBack() {
      document.getElementById('uploadCardBack').click();
    },
    uploadDefault() {
      document.getElementById('UploadDefault').click();
    },
    uploadAccent(index) {
      document.getElementById(`UploadAccent${index}`).click();
    },
    uploadMaterials(index) {
      document.getElementById(`uploadMaterials${index}`).click();
    },
    uploadTeamLogo(index) {
      document.getElementById(`uploadTeamLogo${index}`).click();
    },
    loadTemplate(event) {
      const vm = this;
      this.loadImage(event.target.files[0], vm.constants.defaultIndexTemplate, true, 'Template');
    },
    loadLogo(event) {
      const vm = this;
      this.loadImage(event.target.files[0], vm.constants.defaultIndexLogo, false, 'Logo');
    },
    loadCardBack(event) {
      this.loadImage(event.target.files[0], -1, false, 'BackOfCard');
    },
    loadDefault(event) {
      this.loadImage(event.target.files[0], -1, false, 'Default', 'outputGeneral', true);
      this.selectedCardConfig.Template = document.getElementById('outputGeneral').src;
    },
    loadImage(fileSRC, layer, scale, LayerName, previewObj, defaultPic) {
      const vm = this;
      const image = new Image();
      const file = fileSRC;
      const reader = new FileReader();

      reader.onload = function (e) {
        image.src = e.target.result;
      };
      reader.onerror = function (error) {
        alert(error);
      };
      reader.readAsDataURL(file);

      image.addEventListener('load', () => {
        if (layer >= 0) {
          vm.addLayer(layer, image.src, LayerName);
          vm.saveImage({ data: image.src, layer, Type: 'Layers' });
        }
        if (scale) { vm.setScale(layer, image.width, image.height); }
        if (previewObj) { document.getElementById(previewObj).src = image.src; }
        if (defaultPic) {
          vm.setDefaultPicture(image.src);
          vm.saveImage({ data: image.src, layer, Type: 'DefaultPicture' });
        }
        if (LayerName === 'BackOfCard') {
          let ratio = 0.0;

          if (vm.selectedCardConfig.Orientation === 'H') {
            ratio = vm.selectedCardConfig.width / image.width;
          } else {
            ratio = vm.selectedCardConfig.height / image.height;
          }
          vm.selectedCardConfig.backOfCard = image.src;
          vm.selectedCardConfig.backOfCardScale = ratio;
          console.log(ratio);
          vm.saveImage({ data: image.src, layer, Type: 'backOfCard' });
        }
      });
    },
    loadAccent(event, Index) {
      const vm = this;
      const image = new Image();
      const file = event.target.files[0];
      const reader = new FileReader();

      reader.onload = function (e) {
        image.src = e.target.result;
      };
      reader.onerror = function (error) {
        alert(error);
      };
      reader.readAsDataURL(file);

      image.addEventListener('load', () => {
        vm.saveImage({ data: image.src, layer: Index, Type: 'Colors' });
        vm.layersConfigProps.Accents[Index].File = image.src;
        vm.layersConfigProps.Accents[Index].height = image.height;
        vm.layersConfigProps.Accents[Index].width = image.width;
      });
    },
    loadTeamLogo(event, Index) {
      const vm = this;
      const image = new Image();
      const file = event.target.files[0];
      const reader = new FileReader();

      reader.onload = function (e) {
        image.src = e.target.result;
      };
      reader.onerror = function (error) {
        alert(error);
      };
      reader.readAsDataURL(file);

      image.addEventListener('load', () => {
        vm.saveImage({ data: image.src, layer: Index, Type: 'Logos' });
        vm.layersConfigProps.Logos[Index].File = image.src;
        vm.layersConfigProps.Logos[Index].height = image.height;
        vm.layersConfigProps.Logos[Index].width = image.width;
      });
    },
    loadMaterial(event, Index) {
      const vm = this;
      const image = new Image();
      const file = event.target.files[0];
      const reader = new FileReader();

      reader.onload = function (e) {
        image.src = e.target.result;
      };
      reader.onerror = function (error) {
        alert(error);
      };
      reader.readAsDataURL(file);

      image.addEventListener('load', () => {
        vm.saveImage({ data: image.src, layer: Index, Type: 'Materials' });
        vm.layersConfigProps.Materials[Index].File = image.src;
        vm.layersConfigProps.Materials[Index].height = image.height;
        vm.layersConfigProps.Materials[Index].width = image.width;
      });
    },
    changeOrientation() {
      if (this.selectedCardConfig.Orientation === 'H') {
        Vue.set(this.selectedCardConfig, 'width', 422);
        Vue.set(this.selectedCardConfig, 'height', 303);
      } else if (this.selectedCardConfig.Orientation === 'V') {
        Vue.set(this.selectedCardConfig, 'width', 303);
        Vue.set(this.selectedCardConfig, 'height', 422);
      }

      console.log(this.selectedCardConfig.Orientation);
    },
    showPicture(index) {
      const elem = document.getElementById(`previewImg${index}`);
      this.setStyle(elem, 'visibility', 'visible', 'show');
    },
    hidePicture(index) {
      const elem = document.getElementById(`previewImg${index}`);
      this.setStyle(elem, 'visibility', 'hidden', 'hide');
    },
    showLogo(index) {
      const elem = document.getElementById(`logoPreviewImg${index}`);
      this.setStyle(elem, 'visibility', 'visible', 'show');
    },
    hideLogo(index) {
      const elem = document.getElementById(`logoPreviewImg${index}`);
      this.setStyle(elem, 'visibility', 'hidden', 'hide');
    },
    setStyle(pElem, prop, style, layerStyle) {
      const elem = pElem;
      if (!elem) {
        return;
      }
      if (elem.style) {
        elem.style[prop] = style;
      } else if (document.layers) {
        elem[prop] = layerStyle || style;
      }
    },
    addLayer(index, file, LayerName) {
      const newLayer = {
        animation: 'stand',
        x: 0,
        y: 0,
        flip: 'horizontal',
        moving: null,
        target: null,
        scale: 1,
        alpha: 1,
        file,
        cursor: '1',
        LayerName,
      };
      this.layersConfig.splice(index, 1, newLayer);
    },
    setScale(index, width, height) {
      let scalePercent = 0.0;
      if (this.selectedCardConfig.Orientation === 'H') {
        scalePercent = this.selectedCardConfig.height / height;
      } else {
        scalePercent = this.selectedCardConfig.width / width;
      }

      this.layersConfig[index].scale = scalePercent;
    },
    adjustFontSize(text, layer) {
      let fontsizeNew = layer.Size;
      const len = text.length;

      if (len >= (layer.Limit / 1.5)) {
        fontsizeNew = layer.Size * 1 - (len * 0.13);
      }
      console.log(fontsizeNew);
      return fontsizeNew;
    },
    saveToLocal() {
      if (!this.isSaving) {
        this.isSaving = true;
        setTimeout(() => {
          this.triggerStoreLocal();
          this.isSaving = false;
        }, 3000);
      }
    },
  },
  watch: {
    layersConfig: {
      deep: true,
      handler() {
        this.saveToLocal();
      },
    },
    selectedCardConfig: {
      deep: true,
      handler() {
        this.saveToLocal();
      },
    },
  },
};

export default userPicture;

<template>
  <v-container>
    <span class="d-none d-sm-block">
      <v-row class="text-center">
        <v-col class="mb-4">
          <v-btn
            class="mx-2"
            fab
            dark
            @click="zoom('+')"
            color="primary">
            <v-icon dark>
              mdi-plus
            </v-icon>
          </v-btn>
          Zoom
          <v-btn
            class="mx-2"
            fab
            dark
            @click="zoom('-')"
            color="primary">
            <v-icon dark>
              mdi-minus
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    <v-row class="text-center">
      <v-col class="mb-4">
        <v-btn
          class="mx-2"
          fab
          dark
          @click="rotate('+')"
          color="primary">
          <v-icon dark>
            mdi-rotate-left
          </v-icon>
        </v-btn>
        Rotate
        <v-btn
          class="mx-2"
          fab
          dark
          @click="rotate('-')"
          color="primary">
          <v-icon dark>
            mdi-rotate-right
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="text-center">
      <v-col class="mb-4">
        <v-btn
          class="mx-2"
          fab
          dark
          @click="reset()"
          color="primary">
          <v-icon dark>
            mdi-delete-restore
          </v-icon>
        </v-btn>
        Reset
      </v-col>
    </v-row>
    </span>

    <span id="show_on_XS" class="d-block d-sm-none">
      <v-row>
        <v-col class="text-center" cols="4">
          Zoom
        </v-col>
        <v-col class="text-center" cols="5">
          Rotate
        </v-col>
        <v-col class="text-center" cols="3">
            Reset
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-center" cols="2">
            <v-btn
              small
              @click="zoom('+')"
              color="primary">
              <v-icon dark>
                mdi-plus
              </v-icon>
            </v-btn>
        </v-col>
        <v-col class="text-center" cols="2">
            <v-btn
              small
              @click="zoom('-')"
              color="primary">
              <v-icon dark>
                mdi-minus
              </v-icon>
            </v-btn>
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="text-center" cols="2">
            <v-btn
              small
              @click="rotate('+')"
              color="primary">
              <v-icon dark>
                mdi-rotate-left
              </v-icon>
            </v-btn>
        </v-col>
        <v-col class="text-center" cols="2">
            <v-btn
              small
              @click="rotate('-')"
              color="primary">
              <v-icon dark>
                mdi-rotate-right
              </v-icon>
            </v-btn>
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="text-center" cols="2">
            <v-btn
              small
              @click="reset()"
              color="primary">
              <v-icon dark>
                mdi-delete-restore
              </v-icon>
            </v-btn>
        </v-col>
      </v-row>
    </span>
  </v-container>
</template>

<script>
import Controls from '@/mixins/control';
import userModule from '@/mixins/userModule';

export default {
  name: 'Controls',
  mixins: [Controls, userModule],
  data() {
    return {};
  },
};
</script>

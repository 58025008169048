<template>
  <v-card
    class="pa-2"
    height="100%"
    tile
    outlined>
    <v-row
      class="text-center mt-1"
      cols="10"
      sm="10"
      md="10">
        <v-col>
          <v-btn
            elevation="2"
            large
            raised
            @click="addTextLayer">
            Add Text</v-btn>
          <p></p>
        <template v-if="layersConfigProps.textLayers.length > 0" >
          <template v-for="(texts, Index) in layersConfigProps.textLayers">
            <v-row :key="'v-row' + Index">
              <v-col :key="'v-col' + Index">
                <v-text-field
                  :key="Index"
                  label="Enter field Name"
                  v-model="layersConfigProps.textLayers[Index].Title"
                  hide-details="auto">
                  </v-text-field>
              </v-col>
              <v-col>
                  <v-text-field
                  :key="Index"
                  label="Default Text"
                  v-model="layersConfigProps.textLayers[Index].Text"
                  hide-details="auto">
                  </v-text-field>
              </v-col>
              <v-col
                :key="'DM'+Index"
                cols="12"
                sm="6"
                md="2">
                <v-btn
                  v-if="texts.Title != ''"
                  :key="Index"
                  elevation="2"
                  raised
                  color="error"
                  @click="removeTextLayer(Index)">
                  <v-icon>
                    mdi-delete
                  </v-icon></v-btn>
              </v-col>
              </v-row><v-row  :key="'v-row-3' + Index">
              <v-col>
                  <v-text-field
                  :key="Index"
                  type="number"
                  label="Charactares Limit"
                  v-model="layersConfigProps.textLayers[Index].Limit"
                  hide-details="auto">
                  </v-text-field>
              </v-col>
              <v-col>
                  <v-text-field
                  :key="Index"
                  type="number"
                  label="Font Size"
                  v-model="layersConfigProps.textLayers[Index].Size"
                  hide-details="auto">
                  </v-text-field>
              </v-col>
            </v-row><v-row  :key="'v-row-2' + Index">
              <v-col>
                <ColorPickerComp
                    :colorExport='layersConfigProps.textLayers[Index].Color'
                    :index='Index'
                    :module="'layers'"/>
              </v-col>
              <v-col>
                  <v-select
                    :key="Index"
                    :items="textflip"
                    item-text="Text"
                    item-value="Value"
                    label="Flip"
                    hide-details="auto"
                    v-model="layersConfigProps.textLayers[Index].Flip"
                    outlined></v-select>
              </v-col>
              <v-col>
                  <v-select
                    :key="Index"
                    :items="Align"
                    item-text="Text"
                    item-value="Value"
                    hide-details="auto"
                    label="Align"
                    v-model="layersConfigProps.textLayers[Index].Align"
                    outlined></v-select>
              </v-col>
            </v-row>
            <v-row :key="'v-row2' + Index">
              <v-col  cols="12"
                      sm="12"
                      md="6">
                  <v-slider
                    :key="'posx' + Index"
                    label="Position X"
                    v-model="layersConfigProps.textLayers[Index].posX"
                    :max="canvasProps.width"
                    min="0"></v-slider>
              </v-col>
              <v-col  cols="12"
                      sm="12"
                      md="6">
                  <v-slider
                    :key="'posY' + Index"
                    label="Position Y"
                    v-model="layersConfigProps.textLayers[Index].posY"
                    :max="canvasProps.width"
                    min="0"></v-slider>
              </v-col>
            </v-row>
            <v-divider class="mb-4" :key="'divider' + Index"/>
          </template>
        </template>
        </v-col>
    </v-row>
  </v-card>
</template>

<script>

import setup from '@/mixins/setup';
import ColorPickerComp from '@/components/general/ColorPicker.vue';

export default {
  name: 'SetupText',
  mixins: [setup],
  components: { ColorPickerComp },
  data: () => ({
    Align: [
      { Text: 'Left', Value: 'left' },
      { Text: 'Center', Value: 'center' },
      { Text: 'Right', Value: 'right' }],
    textflip: [
      { Text: 'Normal', Value: 0 },
      { Text: '90', Value: 90 },
      { Text: '180', Value: 180 },
      { Text: '270', Value: 270 }],
  }),
};
</script>

<template>
  <v-text-field
  label='Color'
  v-model="color"
  hide-details
  outlined>
    <template v-slot:append>
      <v-menu v-model="menu"
      top nudge-bottom="105"
      nudge-left="16"
      :close-on-content-click="false">
        <template v-slot:activator="{ on }">
          <div :style="swatchStyle" v-on="on" />
        </template>
        <v-card>
          <v-card-text class="pa-0">
            <v-color-picker
            v-model="color"
            type="hex"
            flat/>
          </v-card-text>
        </v-card>
      </v-menu>
    </template>
  </v-text-field>
</template>

<script>
import setup from '@/mixins/setup';

export default {
  name: 'CardTemplate',
  mixins: [setup],
  props: {
    colorExport: {
      type: String,
      default: '',
    },
    index: {
      type: Number,
      default: 0,
    },
    module: {
      type: String,
      default: '',
    },
  },
  methods: {
  },
  created() {
    this.hex = this.colorExport;
  },
  data: () => ({
    menu: false,
    type: 'hex',
    hex: '#FF00FF',
    rgba: {
      r: 255, g: 0, b: 255, a: 1,
    },
    hsla: {
      h: 300, s: 1, l: 0.5, a: 1,
    },
    hsva: {
      h: 300, s: 1, v: 1, a: 1,
    },
  }),
  computed: {
    swatchStyle() {
      const { color, menu } = this;
      return {
        backgroundColor: color,
        cursor: 'pointer',
        height: '30px',
        width: '30px',
        borderRadius: menu ? '50%' : '4px',
        transition: 'border-radius 200ms ease-in-out',
      };
    },
    color: {
      get() {
        return this[this.type];
      },
      set(v) {
        console.log('ColorPicker');
        this[this.type] = v.substring(0, 7);
        if (this.module === 'layers') {
          this.layersConfigProps.textLayers[this.index].Color = v.substring(0, 7);
        }
        if (this.module === 'logos') {
          this.selectedCard.showShapeColor = v;
        }
        if (this.module === 'back') {
          this.selectedCard.backOfCardColor = v;
        }
      },
    },
  },
};
</script>

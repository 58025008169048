<template>
  <v-container
    class="pa-2"
    height="100%">
    <v-row
      class="text-center mt-1"
      cols="10"
      sm="10"
      md="10">
        <v-col>
        <template v-if="layersProps.textLayers.length > 0" >
          <template v-for="(texts, Index) in layersProps.textLayers">
          <v-text-field
            autocomplete="off"
            :key="Index"
            :label="'Change ' + layersProps.textLayers[Index].Title"
            v-model="layersProps.textLayers[Index].Text"
            counter
            :hint="'A maximun of ' + layersProps.textLayers[Index].Limit
            + ' characters is recomended'"
            hide-details="auto">
            </v-text-field>
            <br :key="`br-${Index}`">
          </template>
        </template>
        </v-col>
    </v-row>
  </v-container>
</template>

<script>

import userModule from '@/mixins/userModule';

export default {
  name: 'CustomizeText',
  mixins: [userModule],
};
</script>

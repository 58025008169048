<template>
  <v-container
    class="pa-2"
    height="100%">
    <v-row
      class="text-center mt-1"
      cols="12"
      sm="12"
      md="12">
        <v-col>
        <template v-if="layersProps.Materials.length > 0" >
          <v-select
            v-model="canvasProps.SelectedMaterial"
            :items="layersProps.Materials"
            item-text="Title"
            item-value="Index"
            label="Select Material"
            @change="setMaterial"
            outlined
          ></v-select>
        </template>
        <div class="transition-swing text-h4 mb-8 mt-4 d-none d-sm-block text-center">
          Upload Your Own Material</div>
        <v-btn
          elevation="2"
          large
          raised
          color='success'
          class="white--text"
          @click="uploadMaterial">
          Upload Picture</v-btn>
          <v-slider
          v-if="1 === 2"
            label="Scale"
            v-model="layers[constants.defaultIndexAddon].scale"
            max="1"
            step="0.01"
            hide-details="auto"
            min="0"></v-slider>
        </v-col>
    </v-row>
  </v-container>
</template>

<script>

import userModule from '@/mixins/userModule';

export default {
  name: 'CustomizeMaterials',
  mixins: [userModule],
};
</script>

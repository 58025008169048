<template>
  <v-container
    class="pa-2"
    height="100%">
    <easel-canvas
        class="canvasMain"
        :width="SelectedCardSetup.width"
        :height="SelectedCardSetup.height"
        id="cardBuilder">
        <easel-bitmap
          :image="SelectedCardSetup.backOfCard"
          :x="0"
          :y="0"
          :scale="SelectedCardSetup.backOfCardScale"
          :alpha="1"
          :cursor="''"
          :align="['left','top']">
        </easel-bitmap>
        <easel-bitmap
          v-if="SelectedCardSetup.backOfCardLink !== ''
          && SelectedCardSetup.backOfCardLink !== 'Include your own link'"
          :image="'https://chart.googleapis.com/chart?chs=200x200&cht=qr&chl=' + SelectedCardSetup.backOfCardLink + '&choe=UTF-8SelectedCardSetup.backOfCard'"
          :x="SelectedCardSetup.width - 99"
          :y="SelectedCardSetup.height - 99"
          :scale="SelectedCardSetup.backOfCardScale"
          :alpha="1"
          :cursor="''"
          :align="['left','top']">
        </easel-bitmap>
        <easel-text
                :align="'top-' + SelectedCardSetup.backOfCardAlign"
                :text="formatText(SelectedCardSetup.backOfCardDefaultText)"
                :shadow="['#969090',2,2,9]"
                :color="SelectedCardSetup.backOfCardColor"
                :font="SelectedCardSetup.backOfCardSize + 'px ' + SelectedCardSetup.Font"
                :x="SelectedCardSetup.backOfCardTextX"
                :y="SelectedCardSetup.backOfCardTextY"
                scale="1">
          </easel-text>
    </easel-canvas>
  </v-container>
</template>

<style>
  @import '../../css/canvas.css';
</style>;

<script>
import setup from '@/mixins/setup';

export default {
  name: 'SetupBack',
  data: () => ({
    emitMove: false,
    emitClick: false,
  }),
  mixins: [setup],
  methods: {
    handleMove(e) {
      const vm = this;
      // console.log(e);
      if (this.emitMove) {
        this.$emit('pressmove', e);
        this.emitMove = false;
      }
      setTimeout(() => { vm.emitMove = true; }, 300);
    },
    formatText(value) {
      const maxWidth = this.SelectedCardSetup.width * 0.9;
      const words = value.split(' ');
      let finalString = '';
      let contPerLine = 0;

      for (let index = 0; index < words.length; index += 1) {
        const word = words[index];
        const lineLen = word.length * this.SelectedCardSetup.backOfCardSize;

        if (contPerLine + lineLen <= maxWidth) {
          finalString += ` ${word}`;
          contPerLine += lineLen;
        } else {
          finalString += `\n ${word}`;
          contPerLine = lineLen;
        }
      }
      return finalString;
    },
    handleDown(e) {
      // console.log(e);
      this.$emit('mousedown', e);
    },
  },
  props: {
    LayersPropsPreview: {
      type: Object,
      default: () => {},
    },
    LayersPreview: {
      type: Array,
      default: () => [],
    },
    LayersMaterialPrev: {
      type: Object,
      default: () => {},
    },
    SelectedCardSetup: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

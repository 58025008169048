import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Canvas from '../views/Canvas.vue';
import Configuration from '../views/Configuration.vue';

Vue.use(VueRouter);
Vue.use('VueEaseljs');

const routes = [
  {
    path: '/',
    name: 'Canvas',
    component: Canvas,
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
  },
  {
    path: '/config',
    name: 'Configuration',
    component: Configuration,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;

<template>
  <v-container
    class="pa-2"
    height="100%">
    <v-row
      class="text-center mt-1"
      cols="12"
      sm="12"
      md="12">
        <v-col>
        <v-btn
          id='downloadButton'
          href='#'
          elevation="2"
          large
          raised
          color='success'
          class="white--text"
          @click="downloadCard">
          Download Card</v-btn>
        </v-col>
    </v-row>
  </v-container>
</template>

<script>

import userModule from '@/mixins/userModule';

export default {
  name: 'DownloadCard',
  mixins: [userModule],
};
</script>

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';
// import firebase from 'firebase';

// firebase init - add your own config here
const firebaseConfig = {
  apiKey: 'AIzaSyCgnaxQfziHk_SjvrZR4yzYEyqdiTPsVDQ',
  authDomain: 'of1-card-builder.firebaseapp.com',
  databaseURL: 'https://of1-card-builder-default-rtdb.firebaseio.com',
  projectId: 'of1-card-builder',
  storageBucket: 'card-builder',
  messagingSenderId: '901545892009',
  appId: '1:901545892009:web:a31fb21af38acafdabb1b8',
};
firebase.initializeApp(firebaseConfig);

// utils
const db = firebase.database();
const auth = firebase.auth();
const storage = firebase.storage();

// // collection references
// const cardsCollection = db.collection('Cards');
// const paramsCollection = db.collection('Params');

// export utils/refs
export {
  db,
  auth,
  storage,
  // cardsCollection,
  // paramsCollection,
};

// export default firebase.database();

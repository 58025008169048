<template>
  <v-card
    class="pa-2"
    height="100%"
    tile
    outlined>
    <v-row
      class="text-center mt-1"
      cols="10"
      sm="10"
      md="10">
        <v-col>
            <v-row>
                <v-col>
                    <v-text-field
                    label="Default Text"
                    v-model="selectedCardConfig.backOfCardDefaultText"
                    hide-details="auto">
                    </v-text-field>
                </v-col>
            </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                  type="number"
                  label="Font Size"
                  v-model="selectedCardConfig.backOfCardSize"
                  hide-details="auto">
                  </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <ColorPickerComp
                    :colorExport='selectedCardConfig.backOfCardColor'
                    :index='0'
                    :module="'back'"/>
              </v-col>
              <v-col>
                  <v-select
                    :items="Align"
                    item-text="Text"
                    item-value="Value"
                    hide-details="auto"
                    label="Align"
                    v-model="selectedCardConfig.backOfCardAlign"
                    outlined></v-select>
              </v-col>
            </v-row>
            <v-divider class="mb-4"/>
        </v-col>
    </v-row>
  </v-card>
</template>

<script>

import setup from '@/mixins/setup';
import ColorPickerComp from '@/components/general/ColorPicker.vue';

export default {
  name: 'SetupBackOfCard',
  mixins: [setup],
  components: { ColorPickerComp },
  data: () => ({
    Align: [
      { Text: 'Left', Value: 'left' },
      { Text: 'Center', Value: 'center' },
      { Text: 'Right', Value: 'right' }],
    textflip: [
      { Text: 'Normal', Value: 0 },
      { Text: '90', Value: 90 },
      { Text: '180', Value: 180 },
      { Text: '270', Value: 270 }],
  }),
};
</script>

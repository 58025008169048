import Vue from 'vue';
import Vuex from 'vuex';

import canvasState from '@/store/modules/canvasState';
import { version } from '../../package.json';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
  },
  mutations: {
    initialiseStore(state) {
      // Check if the ID exists
      if (localStorage.getItem('store')) {
        const store = JSON.parse(localStorage.getItem('store'));

        // Check the version stored against current. If different, don't
        // load the cached version
        if (store.version === version) {
          this.replaceState(
            Object.assign(state, JSON.parse(localStorage.getItem('store'))),
          );
        } else {
          state.version = version;
        }
      }

      // if (localStorage.getItem('store')) {
      //   // Replace the state object with the stored item
      //   this.replaceState(
      //     Object.assign(state, JSON.parse(localStorage.getItem('store'))),
      //   );
      // }
    },
  },
  actions: {
  },
  modules: {
    canvasState,
  },
});
